import React, { PropsWithChildren, useState } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { DialogTitle } from './UtilComponents';

const styles = () =>
  createStyles({
    'dialog-actions': {
      background: '#ECEFF1',
      padding: '16px',
      '& button:not(:first-child)': {
        marginLeft: '40px',
      },
      '& button:first-child': {
        background: '#fff',
      },
    },
  });

type Props = Styles & {
  text?: string;
  title?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};

function ConfirmationDialog({
  text,
  title,
  onConfirm,
  onClose,
  children,
  classes,
}: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    close();
  };

  const close = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    close();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>{title || 'Bestätigen'}</DialogTitle>
      <DialogContent>
        {(text && <DialogContentText>{text}</DialogContentText>) || children}
      </DialogContent>
      <DialogActions className={classes['dialog-actions']}>
        <Button onClick={handleClose} variant="contained">
          Abbrechen
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="secondary"
          autoFocus
        >
          Bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type Styles = WithStyles<typeof styles>;

export default withStyles(styles, { withTheme: true })(ConfirmationDialog);
