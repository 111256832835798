import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import * as DateUtil from 'date-fns';
import { de } from 'date-fns/locale';
import React, { Fragment, ReactElement, useMemo } from 'react';
import './year.css';

const styles = () =>
  createStyles({
    'quarter-container': {
      display: 'flex',
    },
    'quarter-dot': {
      flex: '1',
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '16px',
      padding: '10px 0',
      borderTop: '1px solid #E1E0EA',
      borderLeft: '1px solid #E1E0EA',
      '&:last-child': {
        borderLeft: '1px solid #E1E0EA',
        borderRight: '1px solid #E1E0EA',
      },
      '&.weekend': {
        backgroundColor: '#F6F6F6',
      },
      '&.today': {
        boxShadow: 'inset 0 0 0px 2px #0088D7',
      },
      '& .date': {},
      '& .dayofweek': {
        fontWeight: 300,
      },
    },
  });

type Styles = WithStyles<typeof styles>;

interface Props {
  startDate: Date | number;
  endDate: Date | number;
}

const YearTimeline = ({
  classes,
  startDate,
  endDate,
}: Props & Styles): ReactElement => {
  const monthDates: Date[] = useMemo(
    () => DateUtil.eachDayOfInterval({ start: startDate, end: endDate }),
    [startDate, endDate]
  );

  const getCss = (date: Date | number) => {
    const c = [];
    const d = date instanceof Date ? date : new Date(date);
    c.push(classes['quarter-dot']);
    if (DateUtil.isWeekend(d)) {
      c.push('weekend');
    }
    if (DateUtil.isToday(d)) {
      c.push('today');
    }
    if (DateUtil.isToday(DateUtil.addDays(d, 1))) {
      c.push('yesterday');
    }
    return c.join(' ');
  };

  return (
    <Fragment>
      <div className={classes['quarter-container']}>
        {monthDates.map(q => (
          <div className={getCss(q)} key={q.getTime()}>
            <span className="date">{DateUtil.getDate(q)}</span>
            <br />
            <span className="dayofweek">
              {DateUtil.format(q, 'EE', { locale: de })}
            </span>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(YearTimeline);
