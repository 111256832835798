import { createStyles, Slide, WithStyles, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement, useState } from 'react';
import PipelineSettings from './PipelineSettings';
import Profile from './Profile';

interface Props extends Styles {
  onClose: () => void;
}

const styles = createStyles({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: '4px',
    flex: 1,
  },
  'pipeline-table': {
    width: '450px',
    padding: '8px 8px 0 10px',
  },
  'settings-box': {
    padding: '20px',
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AppSettingsDialog({ classes, onClose }: Props): ReactElement {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Einstellungen
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Schließen
          </Button>
        </Toolbar>
      </AppBar>

      <div className={classes['settings-box']}>
        <Profile />
      </div>
      <div className={classes['settings-box']}>
        <PipelineSettings />
      </div>
    </Dialog>
  );
}

type Styles = WithStyles<typeof styles>;

export default withStyles(styles, { withTheme: true })(AppSettingsDialog);

// export default AppSettings
