import {
  ContactformData,
  DynamoDBObjects,
  getDayKey,
  State,
} from '@maom/aws-dynamodb-interfaces';
import { utcToZonedTime } from 'date-fns-tz';

type NextUser = {
  user?: DynamoDBObjects.User;
  last?: DynamoDBObjects.User;
  update: 'filter' | boolean | undefined;
  filter: DynamoDBObjects.Filter | undefined;
};

export const EMPTY_NEXT_USER: NextUser = {
  user: undefined,
  last: undefined,
  update: false,
  filter: undefined,
};

/**
 * Wir holen uns den nächsten user, der einen Lead/Deal bekommen soll
 * @param userlist list aller User
 * @param form Formdaten vom interesseten
 * @param filterlist liste aller filter, die zu diesem formular passen
 * @param timeframelist Liste aller Timeframes dieser Company
 */
export function getNextUser(
  userlist: Array<DynamoDBObjects.User>,
  form: ContactformData,
  filterlist: Array<DynamoDBObjects.Filter>,
  timeframelist: Array<DynamoDBObjects.Timeframe>
): NextUser {
  if (!userlist || !userlist.length || userlist.length === 0)
    return { ...EMPTY_NEXT_USER };

  const { pipeline_id } = form;
  if (!pipeline_id) return { ...EMPTY_NEXT_USER };

  const resultNextUser: NextUser = { ...EMPTY_NEXT_USER };
  console.log(`starting logic for 'nextUser' and 'lastUser'`);
  const old_list = [...userlist];
  Object.freeze(old_list);

  let lastUserIndex = userlist.findIndex(
    (u) =>
      u.pipeline_to_active &&
      pipeline_id in u.pipeline_to_active &&
      u.pipeline_to_active[pipeline_id].state === State.CLOSED
  );
  if (lastUserIndex >= 0) {
    console.log(
      'found lastUserIndex',
      lastUserIndex,
      userlist.map((u) => ({ i: u.pipedrive_id, n: u.name }))
    );
  }

  // wir checken den old_user, könnte auch einer sein, der momentan nicht aktiv ist.
  let lastUser: undefined | DynamoDBObjects.User = userlist[lastUserIndex];
  if (lastUserIndex === -1 || !lastUser) {
    lastUser = old_list.find(
      (u) =>
        pipeline_id in u.pipeline_to_active &&
        !!u.pipeline_to_active[pipeline_id].state &&
        u.pipeline_to_active[pipeline_id].state === State.CLOSED
    );
  }
  resultNextUser.last = lastUser;

  console.log(
    `starting to filter for users(${userlist.length}), using main user_pipeline_active flag`
  );
  // wir sollten mal nach den aktiven usern filtern
  userlist = userlist.filter((u) => {
    return (
      u &&
      u.pipeline_to_active &&
      pipeline_id in u.pipeline_to_active &&
      u.pipeline_to_active[pipeline_id].active
    );
  });
  console.log(
    `finished filtering for users(${userlist.length}), using main user_pipeline_active flag`
  );
  lastUserIndex = userlist.findIndex(
    (u) =>
      u.pipeline_to_active &&
      pipeline_id in u.pipeline_to_active &&
      u.pipeline_to_active[pipeline_id].state === 1
  );

  if (timeframelist && timeframelist.length > 0) {
    console.log(
      `starting to filter for users(${userlist.length}), using ${timeframelist.length} timeframes`
    );
    const nowDate = new Date();
    const now = nowDate.getTime();
    const userids = userlist.map((u) => u.pipedrive_id);
    // wir suchen uns passende Timeframes zusammen, falls diese vorhanden sind.
    const filteredFrames: {
      [userid: number]: DynamoDBObjects.Timeframe;
    } = timeframelist
      .filter((t) => userids.includes(t.userid))
      .filter((t) => {
        if (!t.end_date && t.start_date <= now) return true;
        else if (t.end_date && t.end_date >= now && t.start_date <= now)
          return true;
        return false;
      })
      .reduce((prev, curr) => {
        prev[curr.userid] = curr;
        return prev;
      }, {} as any);

    // dann filtern wir alle User raus, die einen Timeframe haben, aber kein explizites "true" für heute haben
    userlist = userlist.filter((u) => {
      const frame = filteredFrames[u.pipedrive_id];
      if (!frame) return true;

      const d = getDayKey(nowDate.getDay());
      console.log(`found timeframe for user: ${u.pipedrive_id}|${u.name}`, d);

      const activeOnDay = !!(
        frame.days &&
        pipeline_id in frame.days &&
        d in frame.days[pipeline_id] &&
        frame.days[pipeline_id][d]
      );
      console.log(
        `found timeframe for user: ${u.pipedrive_id}|${u.name}`,
        d,
        `active: ${activeOnDay}`
      );

      if (activeOnDay) {
        if (
          frame.time_settings != null &&
          d in frame.time_settings &&
          frame.time_settings[d].time_active
        ) {
          const timeSettings = frame.time_settings[d];
          const date = utcToZonedTime(nowDate, 'Europe/Berlin');
          const time = date.getHours() * 100 + date.getMinutes();
          console.log(
            'found active timesettings',
            timeSettings,
            time,
            date,
            nowDate
          );
          if (timeSettings.start_time != null) {
            if (timeSettings.end_time != null) {
              return (
                timeSettings.start_time <= time && time <= timeSettings.end_time
              );
            } else {
              return timeSettings.start_time <= time;
            }
          } else if (timeSettings.end_time != null) {
            return time <= timeSettings.end_time;
          }
        } else {
          console.log('no active time_settings found');
        }
      }
      return activeOnDay;
    });
    lastUserIndex = userlist.findIndex(
      (u) =>
        u.pipeline_to_active &&
        pipeline_id in u.pipeline_to_active &&
        u.pipeline_to_active[pipeline_id].state === 1
    );
    console.log(
      `finished filtering for users(${userlist.length}), using ${timeframelist.length} timeframes`
    );
  } else {
    console.log(`no timesframes loaded, ignoring`);
  }

  // kein sinnvoller user mehr in der liste, also können wir das niemandem zuordnen
  if (userlist.length === 0) {
    console.log(
      `no active user for pipeline_id '${pipeline_id}' found, aborting nextUser check`,
      resultNextUser.user
    );
    return resultNextUser;
  }

  lastUserIndex = userlist.findIndex(
    (u) =>
      u.pipeline_to_active &&
      pipeline_id in u.pipeline_to_active &&
      u.pipeline_to_active[pipeline_id].state === 1
  );
  console.log(`finding nextuser in list of ${userlist.length} users`);
  console.log(`after filter new lastuserindex: ${lastUserIndex}`);

  const awaitingNextUser = userlist.find(
    (u) => (u.pipeline_to_active[pipeline_id].state || 0) >= State.AWAITING
  );
  if (awaitingNextUser) {
    // wir bauen unser ergebnis zusammen
    resultNextUser.user = awaitingNextUser;
    resultNextUser.last = lastUser;
    resultNextUser.update = true;
    return resultNextUser;
  }

  let newUserIndex =
    lastUserIndex < 0 || lastUserIndex >= userlist.length - 1
      ? 0
      : lastUserIndex + 1;

  let user: DynamoDBObjects.User | undefined = userlist.find(
    (u) => u.pipedrive_id === userlist[newUserIndex].pipedrive_id
  );
  if (!user) throw new Error('something went wrong.');

  let user_state = user.pipeline_to_active[pipeline_id];

  console.log(
    `starting skip & dpd check with ${user.pipedrive_id}|${user.name}`,
    user_state,
    (user_state.skip != null &&
      user_state.skip > 0 &&
      user_state.skip_count != null &&
      user_state.skip_count < user_state.skip) ||
      (user_state.dpd != null &&
        user_state.dpd > 0 &&
        user_state.dpd_count != null &&
        user_state.dpd_count >= user_state.dpd)
  );
  const start = newUserIndex;
  while (
    (user_state.skip != null &&
      user_state.skip > 0 &&
      user_state.skip_count != null &&
      user_state.skip_count < user_state.skip) ||
    (user_state.dpd != null &&
      user_state.dpd > 0 &&
      user_state.dpd_count != null &&
      user_state.dpd_count >= user_state.dpd)
  ) {
    newUserIndex++;
    user = undefined;
    if (newUserIndex >= userlist.length) {
      newUserIndex = 0;
    }
    if (newUserIndex === start) {
      break;
    }
    user = userlist[newUserIndex];
    user_state = user.pipeline_to_active[pipeline_id];
  }

  if (user != null) {
    console.log(`found user after skipvalue Check ${user.name}`);
  } else {
    console.log(`NO user found after skipvalue check`);
  }
  console.log(
    `after skipvalue Check ${[].length} Users need skip count update`
  );

  // wir bauen unser ergebnis zusammen
  resultNextUser.user = user;
  resultNextUser.last = lastUser;
  resultNextUser.update = true;

  return resultNextUser;
}
