import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import React, { useState, FormEvent } from 'react';

interface Props {
  open: boolean;
  onClose: (value: string | undefined) => void;
}

export default function FilterCreateDialog(props: Props) {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const { onClose, open } = props;

  const handleClose = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const n = name;
    if (!n || n === '') {
      setError('Bitte Name eingeben!');
    } else {
      setName('');
      onClose(n);
    }
  };

  const handleCancel = () => {
    setName('');
    onClose(undefined);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle id="simple-dialog-title">Filter Name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Der Name für den Filter.
          <br />
          <u>Momentan kann der Name im Nachgang nicht geändert werden.</u>
        </DialogContentText>
        <form id="form-name" onSubmit={handleClose}>
          <FormControl error={error !== ''} fullWidth>
            <TextField
              autoFocus
              margin="dense"
              label="Filter Name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              fullWidth
              required
            />
            {error !== '' ? <FormHelperText>{error}</FormHelperText> : ''}
          </FormControl>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Abbrechen
        </Button>
        <Button type="submit" form="form-name" color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
