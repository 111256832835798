import React, { CSSProperties, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, Grid, withStyles, WithStyles } from '@material-ui/core';
import * as DateUtil from 'date-fns';
import { de } from 'date-fns/locale';
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import { compareObjects, SIMPLE_DATE_FORMAT } from '../../util';
import { ReduxActions } from '../../features/aws/dynamodb/actions';
import { getTranslation } from '../../i18n/timeframetype';
import TimeframeDialogForm from './TimeframeDialogClass';

type Timeframe = DynamoDBObjects.Timeframe;

const frameStyles = createStyles({
  hint: {
    fontSize: '0.9em',
    opacity: '0.7',
  },
  entry: {
    cursor: 'pointer',
    transition: 'width 0.9s',
    position: 'absolute',
    top: '50%',
    height: '100%',
    transform: 'translateY(-50%)',
    padding: '4px',
    '&.left': {
      borderRadius: '0 10px 10px 0',
      paddingLeft: 0,
    },
    '&.right': {
      borderRadius: '10px 0 0 10px',
      paddingRight: 0,
    },
    '&.left-right': {
      borderRadius: '0',
      padding: '4px 0',
    },
    '&:hover': {
      minWidth: '7%',
      zIndex: 10,
    },
  },
  'info-container': {
    height: '100%',
    width: '100%',
    borderRadius: '10px',
    background: '#78909C',
    color: '#fff',
    fontWeight: 600,
    boxShadow: '0 1px 2px rgba(0,0,0,0.16)',
    display: 'flex',
    alignItems: 'stretch',
    transition: 'transform .3s ease-out',
    '&.left': {
      borderRadius: '0 10px 10px 0',
    },
    '&.right': {
      borderRadius: '10px 0 0 10px',
    },
    '&.left-right': {
      borderRadius: '0',
    },
    '&:hover': {
      boxShadow: '0 0 15px rgba(0,0,0,0.16)',
      transform: 'scale(1.1)',
    },
    '& div': {
      padding: '4px 2px 4px 10px',
      lineHeight: '1em',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& span.info': {
        fontSize: '0.75rem',
        fontWeight: 400,
      },
    },
    '& button': {
      alignSelf: 'flex-start',
    },
    '&.empty': {
      backgroundColor: '#e1e0e5',
    },
  },
});

interface FrameProps extends Styles {
  frame: Timeframe;
  width?: string;
  startDate: Date | number;
  endDate: Date | number;
  onChange: () => void;
}

const TimeFrameElement = ({
  frame,
  width,
  startDate,
  endDate,
  classes,
  onChange,
}: FrameProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const style: CSSProperties = {};
  if (width && width !== '') {
    style.width = width;
  }
  if (!frame.end_date || DateUtil.isBefore(endDate, frame.end_date)) {
    style.flex = 1;
  }

  const realEndDate = useMemo(() => {
    if (frame.end_date) {
      return DateUtil.format(frame.end_date, SIMPLE_DATE_FORMAT, {
        locale: de,
      });
    }
    if (!frame.end_date) {
      return '';
    }
    return DateUtil.format(endDate, SIMPLE_DATE_FORMAT, { locale: de });
  }, [frame, endDate]);
  const realStartDate = useMemo(() => {
    if (DateUtil.isBefore(frame.start_date, startDate)) {
      return DateUtil.format(frame.start_date, SIMPLE_DATE_FORMAT, {
        locale: de,
      });
    }
    return DateUtil.format(startDate, SIMPLE_DATE_FORMAT, { locale: de });
  }, [frame, startDate]);

  const handleTimeframeDialogClose = () => {
    setOpenDialog(false);
  };

  const containerClasses = (base: string) => {
    const c = [];
    c.push(base);
    if (
      DateUtil.isBefore(frame.start_date, startDate) &&
      (!frame.end_date || DateUtil.isAfter(frame.end_date, endDate))
    ) {
      c.push('left-right');
    } else if (DateUtil.isBefore(frame.start_date, startDate)) {
      c.push('left');
    } else if (!frame.end_date || DateUtil.isAfter(frame.end_date, endDate)) {
      c.push('right');
    }

    return c;
  };

  return (
    <>
      <Grid
        item
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpenDialog(true);
        }}
        className={containerClasses(classes.entry).join(' ')}
      >
        <div
          className={containerClasses(classes['info-container']).join(' ')}
          style={{
            background: frame.type ? frame.type.background : '#C5E1A5',
          }}
        >
          <div>
            {frame.title ||
              (frame.type && getTranslation(frame.type, de)) ||
              '\u00A0'}
            <span className="info">
              {realStartDate}
              {' -\u00A0'}
              {realEndDate}
            </span>
          </div>
        </div>
      </Grid>
      {openDialog && (
        <TimeframeDialogForm
          frame={{ ...frame }}
          onClose={handleTimeframeDialogClose}
          onSave={(f) => {
            if (!compareObjects(frame, f)) {
              f.old_start_date =
                f.start_date !== frame.start_date
                  ? frame.start_date
                  : undefined;
              f.modified = true;
              // updateTimeframe(f);
              ReduxActions.updateTimeframe(f)(dispatch);
              onChange();
            }
          }}
        />
      )}
    </>
  );
};

type Styles = WithStyles<typeof frameStyles>;
const TimeframeElement = withStyles(frameStyles, { withTheme: true })(
  TimeFrameElement
);

export default TimeframeElement;
