import { DynamoDBObjects, DYNDB_TABLES } from '@maom/aws-dynamodb-interfaces';
import AWS from 'aws-sdk';
import DynamoDB, {
  BatchWriteItemInput,
  DocumentClient,
  WriteRequest,
} from 'aws-sdk/clients/dynamodb';
import {
  LoadSpecificTypeResponse,
  LoadSpecificTypeResponseS,
} from '../../pipedrive/types';
import { TimeframeExtended } from '../../../util/interfaces';

type Timeframe = DynamoDBObjects.Timeframe;

AWS.config.update({
  region: 'eu-central-1',
  accessKeyId: 'AKIASAHST44W3Z73NY7J',
  secretAccessKey: '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',
});

export enum DYNAMODB_TYPE {
  PIPELINES,
  SAVE_PIPELINE,
  LOAD_USER,
  UPDATE_USER,
  SAVE_USER,
  SAVE_FILTERS,
  LOAD_FILTERS,
  DELETE_FILTERS,
  LOAD_TIMEFRAMES,
  SAVE_TIMEFRAMES,
  DELETE_TIMEFRAMES,
}

export interface Configuration {
  company_id?: number;
}

class AWSDynamoDB {
  public static Api: AWSDynamoDB = new AWSDynamoDB();

  private client?: DocumentClient = undefined;

  private config: Configuration = {
    company_id: parseInt(process.env.REACT_APP_COMPANY_ID, 10),
  };
  private isInit: boolean = false;

  // constructor() {}

  init(): void {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log(`using local DynamoDB connection`);
        const clientConfig: DynamoDB.ClientConfiguration = {};

        AWS.config.update({
          region: 'localhost',
          // endpoint: "http://localhost:8000",
          // endpoint: "http://192.168.2.150:8000",
          maxRetries: 3,
          accessKeyId: 'viv43t',
          secretAccessKey: '7t5w8',
        });
        clientConfig.endpoint = 'http://localhost:8000';
        // clientConfig.endpoint = "http://192.168.2.150:8000";
        this.client = new DocumentClient({
          service: new DynamoDB(clientConfig),
        });
      } else {
        console.log(`using prod DynamoDB connection`);
        this.client = new DynamoDB.DocumentClient();
      }
    } catch (error) {
      console.error(`Error updating creds`, error);
    }
    // this.client = DynamoDBUtil.createDynamoDbClient();
    process.env.NODE_ENV !== 'production' && console.log(this.client);

    this.isInit = true;
  }

  updateConfiguration(cfg: Configuration) {
    if (cfg && cfg.company_id && !Number.isInteger(cfg.company_id)) {
      cfg.company_id = parseInt(cfg.company_id as any);
    }
    Object.assign(this.config, cfg);
  }

  updateCompanyId(id?: number | string) {
    if (id && typeof id !== 'number') {
      this.config.company_id = parseInt(id as string);
    } else if (typeof id === 'number') {
      this.config.company_id = id;
    }
  }

  getCompanyID(): number | undefined {
    return this.config.company_id;
  }

  async getUserlist(
    ids: string[] | Partial<DynamoDBObjects.User> = []
  ): Promise<
    | Array<DynamoDBObjects.User>
    | LoadSpecificTypeResponseS<DynamoDBObjects.User>
  > {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log('loading user data', ids);
    const { company_id } = this.config;

    if (ids && Array.isArray(ids) && ids.length > 0) {
      let exp = [];
      let expAtrVal: DynamoDB.DocumentClient.ExpressionAttributeValueMap = {
        ':e0480': company_id,
      };
      let i = 481;
      for (const id of ids) {
        const n = ':e0' + i;
        i++;
        exp.push(n);
        expAtrVal[n] = id;
      }

      const flist = await this.client
        .scan({
          TableName: DYNDB_TABLES.USER,
          FilterExpression:
            '#e0480 = :e0480 And #e0481 IN (' + exp.join(',') + ')',
          ExpressionAttributeValues: expAtrVal,
          ExpressionAttributeNames: {
            '#e0480': 'pipedrive_company_id',
            '#e0481': 'pk',
          },
        })
        .promise();

      const result: LoadSpecificTypeResponseS<DynamoDBObjects.User> = {
        ids,
        value: {},
      };
      for (const f of flist.Items as Array<DynamoDBObjects.User>) {
        result.value[f.pk] = f;
      }
      return result;
    } else if (
      ids &&
      !Array.isArray(ids) &&
      typeof ids === 'object' &&
      Object.keys(ids).length > 0
    ) {
      let exp: string[] = [];
      let expAtrVal: DynamoDB.DocumentClient.ExpressionAttributeValueMap = {
        ':e0480': company_id,
      };
      let expAtrNames: DynamoDB.DocumentClient.ExpressionAttributeNameMap = {
        '#e0480': 'pipedrive_company_id',
      };
      let i = 481;
      Object.entries(ids).forEach(([key, value]) => {
        const n = 'e0' + i;
        i++;
        exp.push(`#${n} = :${n}`);
        expAtrVal[`:${n}`] = value;
        expAtrNames[`#${n}`] = key;
      });

      const userList = await this.client
        .scan({
          TableName: DYNDB_TABLES.USER,
          FilterExpression: '#e0480 = :e0480 AND ' + exp.join(' AND '),
          ExpressionAttributeValues: expAtrVal,
          ExpressionAttributeNames: expAtrNames,
        })
        .promise();

      const result: LoadSpecificTypeResponseS<DynamoDBObjects.User> = {
        ids: Object.values(ids).map(i => '' + i),
        value: {},
      };
      for (const f of userList.Items as Array<DynamoDBObjects.User>) {
        result.value[f.pk] = f;
      }
      return result;
    } else {
      const userlistData = await this.client
        .query({
          TableName: DYNDB_TABLES.USER,
          KeyConditionExpression: '#cid = :cid',
          ExpressionAttributeNames: {
            '#cid': 'pipedrive_company_id',
          },
          ExpressionAttributeValues: {
            ':cid': company_id,
          },
        })
        .promise();

      console.log(
        `loaded ${userlistData.Count} user for company ${company_id}`
      );

      return userlistData.Items as Array<DynamoDBObjects.User>;
    }
  }

  async saveUserlist(userlist: DynamoDBObjects.User[]) {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`saving user list `, userlist.length);

    const marshalled: DocumentClient.WriteRequests = userlist.map(u => {
      return {
        PutRequest: { Item: u },
      };
    });

    const ri: BatchWriteItemInput = { RequestItems: {} };
    ri.RequestItems[DYNDB_TABLES.USER] = marshalled;
    try {
      await this.client.batchWrite(ri).promise();
    } catch (error) {
      console.error(`writing ${userlist.length} users to DB`);
    }
  }

  async getPipelines(): Promise<Array<DynamoDBObjects.Pipeline>> {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`loading pipeline data`);
    const { company_id } = this.config;

    const pipelinelistData = await this.client
      .query({
        TableName: DYNDB_TABLES.PIPELINE,
        KeyConditionExpression: '#cid = :cid',
        ExpressionAttributeNames: {
          '#cid': 'pipedrive_company_id',
        },
        ExpressionAttributeValues: {
          ':cid': company_id,
        },
      })
      .promise();

    console.log(
      `loaded ${pipelinelistData.Count} pipelines for company ${company_id}`
    );

    return pipelinelistData.Items as Array<DynamoDBObjects.Pipeline>;
  }

  async savePipelines(pipelinelist: DynamoDBObjects.Pipeline[]) {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`saving pipeline list `, pipelinelist.length);

    const marshalled: DocumentClient.WriteRequests = pipelinelist.map(u => {
      return {
        PutRequest: { Item: u },
      };
    });

    const ri: BatchWriteItemInput = { RequestItems: {} };
    ri.RequestItems[DYNDB_TABLES.PIPELINE] = marshalled;
    try {
      await this.client.batchWrite(ri).promise();
    } catch (error) {
      console.error(`writing ${pipelinelist.length} pipelines to DB`);
    }
  }

  async getFilters(
    ids: number[] = []
  ): Promise<
    | Array<DynamoDBObjects.Filter>
    | LoadSpecificTypeResponse<DynamoDBObjects.Filter>
  > {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`loading filter data ${ids}`);
    const { company_id } = this.config;

    if (ids && ids.length > 0) {
      let exp = [];
      let expAtrVal: DynamoDB.DocumentClient.ExpressionAttributeValueMap = {
        ':e0480': company_id,
      };
      let i = 481;
      for (const id of ids) {
        const n = ':e0' + i;
        i++;
        exp.push(n);
        expAtrVal[n] = id;
      }

      const flist = await this.client
        .scan({
          TableName: DYNDB_TABLES.FILTER,
          FilterExpression:
            '#e0480 = :e0480 And #e0481 IN (' + exp.join(',') + ')',
          ExpressionAttributeValues: expAtrVal,
          ExpressionAttributeNames: {
            '#e0480': 'pipedrive_company_id',
            '#e0481': 'sort_key',
          },
        })
        .promise();

      const result: LoadSpecificTypeResponse<DynamoDBObjects.Filter> = {
        ids,
        value: {},
      };
      ids.forEach(i => (result.value[i] = undefined));
      for (const f of flist.Items as Array<DynamoDBObjects.Filter>) {
        result.value[f.sort_key] = f;
      }
      return result;
    } else {
      const filterlistData = await this.client
        .query({
          TableName: DYNDB_TABLES.FILTER,
          KeyConditionExpression: '#cid = :cid',
          ExpressionAttributeNames: {
            '#cid': 'pipedrive_company_id',
          },
          ExpressionAttributeValues: {
            ':cid': company_id,
          },
        })
        .promise();
      console.log(
        `loaded ${filterlistData.Count} filter for company ${company_id}`
      );

      const filter = filterlistData.Items as Array<DynamoDBObjects.Filter>;
      return filter.sort((a, b) => a.sort_key - b.sort_key);
    }
  }

  async getTimeframes(
    ids: number[] = []
  ): Promise<Array<Timeframe> | LoadSpecificTypeResponse<Timeframe>> {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`loading timeframe data ${ids}`);
    const { company_id } = this.config;

    if (ids && ids.length > 0) {
      let exp = [];
      let expAtrVal: DynamoDB.DocumentClient.ExpressionAttributeValueMap = {
        ':e0480': company_id,
      };
      let i = 481;
      for (const id of ids) {
        const n = ':e0' + i;
        i++;
        exp.push(n);
        expAtrVal[n] = id;
      }

      const tflist = await this.client
        .scan({
          TableName: DYNDB_TABLES.TIMEFRAME,
          FilterExpression:
            '#e0480 = :e0480 And #e0481 IN (' + exp.join(',') + ')',
          ExpressionAttributeValues: expAtrVal,
          ExpressionAttributeNames: {
            '#e0480': 'pipedrive_company_id',
            '#e0481': 'pk',
          },
        })
        .promise();

      const result: LoadSpecificTypeResponse<Timeframe> = {
        ids,
        value: {},
      };
      ids.forEach(i => (result.value[i] = undefined));
      for (const f of tflist.Items as Array<Timeframe>) {
        result.value[f.id] = f;
      }
      return result;
    } else {
      const timeframelistData = await this.client
        .query({
          TableName: DYNDB_TABLES.TIMEFRAME,
          KeyConditionExpression: '#cid = :cid',
          ExpressionAttributeNames: {
            '#cid': 'pipedrive_company_id',
          },
          ExpressionAttributeValues: {
            ':cid': company_id,
          },
        })
        .promise();
      console.log(
        `loaded ${timeframelistData.Count} timeframes for company ${company_id}`
      );

      const filter = timeframelistData.Items as Array<Timeframe>;
      return filter.sort((a, b) => a.start_date - b.start_date);
    }
  }

  async saveTimeframes(timeframelist: TimeframeExtended[]) {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');

    const modifiedFrames = timeframelist.filter(t => t.modified);
    modifiedFrames.forEach(t => {
      delete t.modified;
    });

    const marshalled: DocumentClient.WriteRequests = modifiedFrames.map(t => {
      if (!!t.old_start_date) {
        return {
          DeleteRequest: {
            Key: {
              pipedrive_company_id: t.pipedrive_company_id,
              id: t.id,
            },
          },
        } as WriteRequest;
      }
      delete t.old_start_date;
      return {
        PutRequest: { Item: t },
      };
    });

    console.log(
      `saving timeframe list `,
      marshalled.length,
      marshalled.filter(m => 'DeleteRequest' in m).length
    );
    if (marshalled.length === 0) return;

    const ri: BatchWriteItemInput = { RequestItems: {} };
    ri.RequestItems[DYNDB_TABLES.TIMEFRAME] = marshalled;
    try {
      await this.client.batchWrite(ri).promise();
      const startChangedFrames = timeframelist.filter(t => !!t.old_start_date);
      if (startChangedFrames.length > 0) {
        console.log(
          `creating '${startChangedFrames.length}' old frames new (id changed)`
        );
        for (const t of startChangedFrames) {
          delete t.old_start_date;
          t.modified = true;
          t.id = t.start_date + t.userid;
        }
        await this.saveTimeframes(startChangedFrames);
      }
    } catch (error) {
      console.error(`writing ${timeframelist.length} pipelines to DB`);
    }
  }

  async deleteTimeframes(ids: number[]) {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`deleting timeframes `, ids.length, ids);
    if (ids.length === 0) return [];

    const { company_id } = this.config;

    const marshalled: DocumentClient.WriteRequests = ids.map(i => {
      return {
        DeleteRequest: {
          Key: {
            pipedrive_company_id: company_id,
            id: i,
          },
        },
      };
    });

    const ri: BatchWriteItemInput = { RequestItems: {} };
    ri.RequestItems[DYNDB_TABLES.TIMEFRAME] = marshalled;

    try {
      await this.client.batchWrite(ri).promise();
    } catch (error) {
      console.error(`deleting ${ids.length} timeframes from DB`, error);
      throw error;
    }

    return ids;
  }

  async saveFilterList(filterlist: DynamoDBObjects.Filter[]) {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`saving filter list `, filterlist.length, filterlist);
    if (filterlist.length === 0) return;

    const marshalled: DocumentClient.WriteRequests = filterlist.map(u => {
      if (u.pipedrive_company_id === -1) {
        u.pipedrive_company_id = this.config.company_id || -1;
      }
      return {
        PutRequest: { Item: u },
      };
    });

    const ri: BatchWriteItemInput = { RequestItems: {} };
    ri.RequestItems[DYNDB_TABLES.FILTER] = marshalled;
    try {
      await this.client.batchWrite(ri).promise();
    } catch (error) {
      console.error(`writing ${filterlist.length} filter to DB`, error);
      throw error;
    }
  }

  async deleteFilter(sortkeys: number[]) {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    console.log(`deleting filter list `, sortkeys.length, sortkeys);
    if (sortkeys.length === 0) return [];

    const { company_id } = this.config;

    const marshalled: DocumentClient.WriteRequests = sortkeys.map(sk => {
      return {
        DeleteRequest: {
          Key: {
            pipedrive_company_id: company_id,
            sort_key: sk,
          },
        },
      };
    });

    const ri: BatchWriteItemInput = { RequestItems: {} };
    ri.RequestItems[DYNDB_TABLES.FILTER] = marshalled;

    try {
      await this.client.batchWrite(ri).promise();
    } catch (error) {
      console.error(`deleting ${sortkeys.length} filter from DB`, error);
      throw error;
    }

    return sortkeys;
  }

  async loadTimeframeTypes(): Promise<Array<DynamoDBObjects.Timeframetype>> {
    if (!this.isInit || !this.client) throw Error('DynamoDB API not init');
    const { company_id } = this.config;

    const timeframetypes = await this.client
      .query({
        TableName: DYNDB_TABLES.TIMEFRAME_TYPE,
        KeyConditionExpression: '#cid = :cid',
        ExpressionAttributeNames: {
          '#cid': 'pipedrive_company_id',
        },
        ExpressionAttributeValues: {
          ':cid': company_id,
        },
      })
      .promise();
    console.log(
      `loaded ${timeframetypes.Count} timeframetypes for company ${company_id}`
    );

    return timeframetypes.Items as Array<DynamoDBObjects.Timeframetype>;
  }
}

export default AWSDynamoDB.Api;
