import { Store } from 'redux';
import { logger } from '.';
import { updateErrorMessageAction } from '../features/util/message-action';
import { getCognitoUser, loadData } from '../features/store';
import { updateAppUserAction } from '../features/userReducer';
import { ReduxActions } from '../features/aws/dynamodb/actions';
import AbilityEventTarget from '../casl/CaslCan';
import { guestUserAbility } from '../casl';

class Hublistener {
  private store: Store | undefined = undefined;

  public static INSTANCE = new Hublistener();

  public setStore(store: Store) {
    this.store = store;
  }

  public listener() {
    return (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          logger.log('user signed in'); // [ERROR] My-Logger - user signed in
          this.updateStore();
          // window.location.reload();
          break;
        case 'signUp':
          logger.info('user signed up');
          break;
        case 'signOut':
          logger.info('user signed out');
          this.updateLogout();
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          if (this.store)
            this.store.dispatch(
              updateErrorMessageAction(
                data.payload.data.message || 'Login failed!'
              )
            );
          // updateErrorMessageDispatch('Login failed')(store.dispatch);
          break;
        case 'configured':
          logger.log('the Auth module is configured');
          break;
        default:
          logger.info(`unknown event: ${data.payload.event}`);
      }
    };
  }

  updateStore() {
    if (this.store) {
      getCognitoUser().then((attr) => {
        loadData(attr).then(
          ({
            users,
            pipelines,
            filters,
             timeframes,
            timeframetypes,
            ability,
            dynamodbUser,
          }) => {
            AbilityEventTarget.updateAbility(ability);

            if (dynamodbUser)
              this.store?.dispatch(updateAppUserAction(dynamodbUser));
            this.store?.dispatch(
              ReduxActions.updateInitData([
                users,
                pipelines,
                filters,
                timeframes,
                timeframetypes,
              ])
            );
          }
        );
      });
    }
  }

  updateLogout() {
    AbilityEventTarget.updateAbility(guestUserAbility);
  }
}

export default Hublistener.INSTANCE;
