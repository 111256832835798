import React, { PropsWithChildren, useState } from 'react';
import { Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DialogTitle } from '../components/util/UtilComponents';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '20vh',
  },
  content: {
    padding: theme.spacing(2, 4, 3),
  },
  version: {
    position: 'absolute',
    right: '8px',
    bottom: '2px',
    opacity: 0.7,
  },
}));

type Props = {
  onClose?: () => void;
};

function AboutModal({ onClose }: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleClose = () => {
    close();
  };

  const close = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.paper}>
        <DialogTitle onClose={handleClose}>
          About SimpleCheck Dashboard
        </DialogTitle>
        <div className={classes.content}>
          {/*<Typography variant="body1">Lizensen etc hallo</Typography>*/}
          <Typography variant="body1">
            Copyright © {new Date().getFullYear()} My Art of Mind GmbH
          </Typography>
        </div>
        <div className={classes.version}>
          <Typography align="right" variant="body1">
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </div>
      </div>
    </Modal>
  );
}

export default AboutModal;
