import {
  createStyles,
  Grid,
  GridProps,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as DateUtil from 'date-fns';
import { de } from 'date-fns/locale';
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import { SIMPLE_DATE_FORMAT } from '../../util';
import TimeframeElement from './TimeframeElement';

type Timeframe = DynamoDBObjects.Timeframe;

type FrameInterval = DateUtil.Interval & {
  frame: Timeframe;
  width?: string;
};

type FrameIntervalHolder = { [day: number]: FrameInterval };

interface FrameContainerProps extends Styles {
  frames: Timeframe[];
  startDate: Date | number;
  endDate: Date | number;
  onChange: () => void;
  openCreateDialog: (dayofmonth: number) => void;
}

const FrameContainer = ({
  frames,
  startDate,
  endDate,
  onChange,
  openCreateDialog,
  classes,
}: FrameContainerProps) => {
  const diffInDays = useMemo(
    () => DateUtil.differenceInDays(endDate, startDate) + 1,
    [endDate, startDate]
  );
  const [itemWidth, setItemWidth] = useState(0);
  const firstItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ev = () => {
      if (firstItemRef && firstItemRef.current) {
        const client = firstItemRef.current.getBoundingClientRect();
        setItemWidth(client.width);
      }
    };
    ev();
    window.addEventListener('resize', ev);
    return () => window.removeEventListener('resize', ev);
  }, [firstItemRef]);

  useEffect(() => {
    if (firstItemRef && firstItemRef.current) {
      const client = firstItemRef.current.getBoundingClientRect();
      setItemWidth(client.width);
    }
  }, [startDate]);

  const intervalls: FrameIntervalHolder = useMemo(() => {
    const inters: FrameIntervalHolder = {};
    let currentDate: number | Date = startDate;

    function getWidth(i: FrameInterval): string | undefined {
      const days = DateUtil.differenceInCalendarDays(
        DateUtil.endOfDay(i.end),
        DateUtil.endOfDay(i.start)
      );
      if (days >= 0) {
        return `${Math.round(itemWidth * days + itemWidth)}px`;
      }
      return undefined;
    }

    frames
      .filter(
        f =>
          DateUtil.areIntervalsOverlapping(
            {
              start: f.start_date,
              end: f.end_date ? f.end_date : f.start_date + 1,
            },
            { start: startDate, end: endDate }
          ) ||
          (!f.end_date && DateUtil.isBefore(f.start_date, startDate))
      )
      .forEach(f => {
        let s: number | Date;
        let e: number | Date;
        if (DateUtil.isBefore(f.start_date, currentDate)) {
          s = DateUtil.startOfDay(currentDate);
        } else {
          s = f.start_date;
        }

        if (f.end_date != null) {
          if (DateUtil.isBefore(f.end_date, endDate)) {
            e = f.end_date;
            currentDate = DateUtil.addDays(e, 1);
          } else {
            e = endDate;
            currentDate = e;
          }
        } else {
          e = endDate;
          currentDate = e;
        }
        const i: FrameInterval = {
          start: s,
          end: e,
          frame: f,
        };

        if (!i.end) {
          console.log(
            DateUtil.format(startDate, SIMPLE_DATE_FORMAT, {
              locale: de,
            }),
            DateUtil.format(endDate, SIMPLE_DATE_FORMAT, { locale: de }),
            currentDate,
            i,
            f.id,
            f.start_date,
            f.end_date
          );
          throw Error('no end date for intervall');
        }
        i.width = getWidth(i);

        inters[DateUtil.getDate(i.start)] = i;
      });

    return inters;
  }, [startDate, endDate, frames, itemWidth]);

  const grids = useMemo(() => {
    return Array.from({ length: diffInDays }, (_, k) => {
      const gridItemProps: GridProps = {
        item: true,
        key: k,
        className: classes.emptyItem,
      };
      if (DateUtil.isWeekend(DateUtil.addDays(startDate, k))) {
        gridItemProps.className += ' weekend';
      }
      if (k === 0) gridItemProps.ref = firstItemRef;
      const interval: FrameInterval | undefined = intervalls[k + 1];
      if (!interval) {
        gridItemProps.onClick = () => openCreateDialog(k);
        return <Grid {...gridItemProps} />;
      }
      return (
        <Grid {...gridItemProps}>
          <TimeframeElement
            width={interval.width || undefined}
            key={
              interval.start instanceof Date
                ? interval.start.getTime()
                : interval.start
            }
            frame={interval.frame}
            startDate={interval.start}
            endDate={interval.end}
            onChange={onChange}
          />
        </Grid>
      );
    });
  }, [
    intervalls,
    onChange,
    startDate,
    openCreateDialog,
    diffInDays,
    classes.emptyItem,
  ]);

  return (
    <Grid
      container
      // wrap="nowrap"
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      style={{ position: 'relative' }}
    >
      {grids}
    </Grid>
  );
};
const styles = createStyles({
  emptyItem: {
    borderLeft: '1px solid #E1E0EA',
    cursor: 'pointer',
    flex: 1,
    height: '60px',
    '&:last-child': {
      borderRight: '1px solid #E1E0EA',
    },
    '&.weekend': {
      backgroundColor: '#f2f2f2',
    },
  },
  itemContainer: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    display: 'flex',
  },
});
type Styles = WithStyles<typeof styles>;

export default withStyles(styles, { withTheme: true })(FrameContainer);
