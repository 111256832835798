import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';

export function getTranslation(
  type: DynamoDBObjects.Timeframetype | undefined,
  locale: Locale
): string {
  if (!type) {
    console.warn(`unknow type`);
    return '';
  }
  if (locale.code && locale.code in type.translation) {
    return type.translation[locale.code];
  }
  console.warn(
    `no translation for Timeframetype '${type.key}' and locale: '${locale.code}'`
  );
  return type.translation[''];
}
