import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { ExpandMore, InfoOutlined } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import ConsultOverview from '../../components/consult-overview';
import FilterSettings from '../../components/filter-settings/FilterSettings';
// import FilterSettingsAdv from '../../components/filter-settings/FilterSettingsAdv';
import Timeframes from '../../components/timeframes/Timeframes';

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, style } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && (
        <Box m={3} style={style || {}}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

export const ConsultOverviewPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  // eslint-disable jsx-props-no-spreading
  return (
    <Fragment>
      <Paper>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              height: '6px',
              backgroundColor: '#7CB342',
            },
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Piplines" {...a11yProps(0)} />
          <Tab label="Filter" {...a11yProps(1)} />
          <Tab label="Timeframes" {...a11yProps(2)} />
          {/* <Tab label="FilterAdv" {...a11yProps(2)} /> */}
        </Tabs>
      </Paper>
      <TabPanel value={tabIndex} index={0}>
        <h4>Pipline Zuordnung</h4>
        <p>
          Damit ein Berater Leads erhalten kann, muss er in der jeweiligen
          Kampagne eingeschaltet sein. Ein Filter allein reicht nicht.
        </p>

        <Accordion style={{ width: '60%' }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <InfoOutlined color="action" style={{ marginRight: '10px' }} />
            Überspringen (Skip)
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Typography>
              Gibt die Anzahl an Leads an, die der Berater überspringt, bis er
              einen neuen Lead bekommt.
              <br />
              Es werden nur Leads gezählt, die der Berater aufgrund der
              Auswertung auch bekommen sollte. Es werden nicht generell alle
              Leads gezählt.
            </Typography>
            <h4>Beispiel:</h4>
            <Typography>
              Berater: Reza, Sven und Thomas.
              <br />
              Reza hat einen <i>Skip</i> Wert von 1. Die Leads werden
              aufsteigend nach dem Alphabet zugeordnet.
            </Typography>
            <ul>
              <li>
                1. Lead solte an Reza, da wir aber einen Lead überspringen, geht
                dieser Lead an Sven
              </li>
              <li>2. Lead an Thomas</li>
              <li>3. Lead an Reza (diesmal überspringen wir nicht)</li>
              <li>4. Lead an Sven</li>
              <li>5. Lead an Thomas</li>
              <li>6. Lead an Sven (wir überspringen wieder)</li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <ConsultOverview />
      </TabPanel>

      <TabPanel style={{ position: 'relative' }} value={tabIndex} index={1}>
        <FilterSettings />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <Paper style={{ paddingRight: '10px' }}>
          <Timeframes />
        </Paper>
      </TabPanel>

      {/* <TabPanel style={{ position: 'relative' }} value={tabIndex} index={2}>
        <FilterSettingsAdv />
      </TabPanel> */}
    </Fragment>
  );
};
