import React, {
  Fragment,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  AppBar,
  Button,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { AccountCircle, Person } from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { connect, ConnectedProps, useStore } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { AbilityContext, Can } from '../../casl/CaslCan';
import { selectors } from '../../features/pipedrive';
import { resetStore, RootState } from '../../features/store';
import AppSettingsDialog from '../app-settings/AppSettingsDialog';
import { Actions, Subjects } from '../../casl';
import AboutModal from '../../pages/About';

interface GreetingsProps extends RouteComponentProps, ReduxProps, Styles {}

const styles = createStyles({
  header: {
    padding: '10px 0',
    '& button': {
      '& svg': {
        marginRight: '15px',
      },
      '& a': {
        textDecoration: 'none',
      },
    },
    '& .logo': {
      marginLeft: '40px',
    },
  },
  navlink: {
    alignItems: 'center',
    display: 'flex',
    color: '#fff',
    '&.active span': {
      borderBottom: '3px solid #fff',
    },
  },
  splitter: {
    width: '55px',
  },
});

const GreetingsBar: React.FunctionComponent<GreetingsProps> = ({
  classes,
  location,
  user,
}: GreetingsProps) => {
  const reduxStore = useStore();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuState, setMenuState] = useState<{
    open: boolean;
    anchor?: HTMLElement;
  }>({
    open: false,
    anchor: undefined,
  });

  const ability = useContext(AbilityContext);

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState) => {
      setSignedIn(nextAuthState === AuthState.SignedIn);
    });
  }, []);

  const handleClose = () => {
    setMenuState({
      open: false,
      anchor: undefined,
    });
  };

  const logout = useCallback(() => {
    console.log(`user is loging out`);
    handleClose();
    Auth.signOut()
      .then(() => {
        resetStore(reduxStore);
      })
      .catch((err) => console.log(err));
  }, [reduxStore]);

  const showAbout = useCallback(() => {
    handleClose();
    setAboutOpen(true);
  }, []);

  const profile = useMemo(() => {
    let profile: JSX.Element | string = '';
    if (!signedIn) return profile;
    const name: string = user?.name || 'Unbekannter User';

    const menuItems = [];
    menuItems.push(
      <MenuItem key="profile" disabled>
        Profil
      </MenuItem>
    );
    if (ability.can(Actions.SEE, Subjects.USER_SETTINGS)) {
      menuItems.push(
        <MenuItem key="settings" onClick={() => setSettingsOpen(true)}>
          Einstellungen
        </MenuItem>
      );
    }
    menuItems.push(
      <MenuItem key="about" onClick={() => showAbout()}>
        Über
      </MenuItem>
    );
    menuItems.push(<Divider key="divider-0" />);
    menuItems.push(
      <MenuItem key="logout" onClick={() => logout()}>
        Abmelden
      </MenuItem>
    );

    profile = (
      <div>
        <Button
          aria-label="Profile Menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(e: MouseEvent<HTMLButtonElement>) =>
            setMenuState({ open: true, anchor: e.currentTarget })
          }
          color="inherit"
          endIcon={<AccountCircle />}
        >
          {name}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={menuState.anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuState.open}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
          {menuItems}
        </Menu>
      </div>
    );
    return profile;
  }, [
    ability,
    menuState.anchor,
    menuState.open,
    signedIn,
    user,
    logout,
    showAbout,
  ]);

  const getNavClass = (route: string): string => {
    let base = classes.navlink;

    if (location.pathname === route) base += ' active';
    return base;
  };

  return (
    <Fragment>
      <AppBar position="fixed" elevation={0} className={classes.header}>
        <Toolbar>
          <NavLink className={classes.navlink} to="/">
            <img className="logo" src="/Logo.svg" alt="" aria-hidden />
          </NavLink>
          <div style={{ flexGrow: 1 }} />
          {signedIn && (
            <Can I={Actions.SEE} a={Subjects.CONSULT_PREFERENCES}>
              <Button>
                <Typography variant="h6">
                  <NavLink
                    className={getNavClass('/consult-overview')}
                    to="/consult-overview"
                  >
                    <Person />
                    <span>Berater</span>
                  </NavLink>
                </Typography>
              </Button>
            </Can>
          )}
          <div className={classes.splitter} />
          {profile}
        </Toolbar>
      </AppBar>
      {settingsOpen && (
        <AppSettingsDialog
          onClose={() => {
            setSettingsOpen(false);
          }}
        />
      )}
      {aboutOpen && <AboutModal onClose={() => setAboutOpen(false)} />}
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectors.getCurrentUser(state),
});
// const mapDispatchToProps = dispatch => ({
// })

type Styles = WithStyles<typeof styles>;
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(
  withRouter(withStyles(styles, { withTheme: true })(GreetingsBar))
);
