// eslint-disable react/destructuring-assignment
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { PauseCircleOutline, SkipNext } from '@material-ui/icons';
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApiActions, ReduxActions } from '../../features/aws/dynamodb/actions';
import { selectors } from '../../features/pipedrive';
import { RootState } from '../../features/store';
import Savable from '../../util/interfaces/Savable';
import { getInitials } from '../../util';
import NextUserPaper from './NextUserPaper';

interface ConsultProps extends ReduxProps, Styles {
  user: Array<User>;
}

interface ConsultState {
  // pipelines: Array<DynamoDBObjects.Pipeline>;
  dataChanged: boolean;
}

export interface User extends DynamoDBObjects.User {
  modified?: boolean;
}

const styles = createStyles({
  hint: {
    fontSize: '0.9em',
    opacity: '0.7',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  avatarCol: {
    width: '50px',
    backgroundColor: '#F6F5FC',
    border: 'none',
    borderBottom: '1px solid #F6F5FC',
  },
  avatar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
  },
  avatarContainer: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    width: '48px',
    borderRadius: '50%',
    height: '48px',
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  namecol: {
    maxWidth: '210px',
    width: '210px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    backgroundColor: '#F6F5FC',
    border: 'none',
    borderRight: '10px solid #fff',
    borderBottom: '1px solid #F6F5FC',
  },
  'button-area': {
    padding: '4px',
    margin: '4px',
    '& button': {
      marginLeft: '10px',
    },
  },
  'grid-right': {
    'justify-content': 'flex-end',
  },
  'grid-new-item': {
    marginLeft: '10px',
  },
});

class ConsultOverview
  extends React.Component<ConsultProps, ConsultState>
  implements Savable {
  constructor(props: ConsultProps) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleNextUserChange = this.handleNextUserChange.bind(this);

    this.state = {
      // pipelines: (props.pipelines || []).filter(p => p.active === true),
      dataChanged: props.user.some((u) => u.modified),
    };
  }

  handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    u: User,
    pipeId: number,
    index: number
  ) {
    const { updateUser } = this.props;
    const { target } = event;
    const value = target.checked;
    // setChecked(value);
    const user = { ...u };
    if (!user.pipeline_to_active) {
      user.pipeline_to_active = {};
    }
    if (!(pipeId in user.pipeline_to_active)) {
      user.pipeline_to_active[pipeId] = {};
    } else if (
      typeof user.pipeline_to_active[pipeId] !== 'object' ||
      !('active' in user.pipeline_to_active[pipeId])
    ) {
      user.pipeline_to_active[pipeId] = {};
    }
    user.pipeline_to_active[pipeId].active = value;
    console.log(`setting user '${user.name}' in pipline ${pipeId} to ${value}`);

    user.modified = true;
    updateUser(user, index);
    this.setState({ dataChanged: true });
  }

  handleSkipChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    u: User,
    pipeId: number,
    index: number
  ) {
    const stringValue = event.target.value;
    let value: number | undefined = parseInt(stringValue, 10);
    if (Number.isNaN(value) || value < 0) {
      value = undefined;
    }
    this.updatePipeToActiveValue(u, pipeId, index, value, 'skip', 'skip_count');
  }

  private updatePipeToActiveValue(
    user: User,
    pipeId: number,
    index: number,
    value: number | undefined,
    mainKey: 'dpd' | 'skip',
    countKey: 'dpd_count' | 'skip_count'
  ) {
    const { updateUser } = this.props;
    const u = user;
    if (!u.pipeline_to_active) {
      u.pipeline_to_active = {};
    }
    if (
      !(pipeId in u.pipeline_to_active) ||
      u.pipeline_to_active[pipeId] == null
    ) {
      u.pipeline_to_active[pipeId] = {};
    }
    u.pipeline_to_active[pipeId][mainKey] = value;
    if (
      u.pipeline_to_active[pipeId][countKey] == null ||
      u.pipeline_to_active[pipeId][mainKey] === 0
    ) {
      u.pipeline_to_active[pipeId][countKey] = 0;
    }
    u.modified = true;
    updateUser(u, index);
    this.setState({ dataChanged: true });
  }

  handleNextUserChange(changes: { user: DynamoDBObjects.User; index: number }[]) {
    const { updateUser } = this.props;
    console.log(changes);
    changes.forEach((c) => {
      console.log(c.user.name, c.index);
      updateUser({ ...c.user, modified: true } as User, c.index);
    });
    // updateUser({ ...user, modified: true } as User, index);
    this.setState({ dataChanged: true });
  }

  handleDPDChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    u: User,
    pipeId: number,
    index: number
  ) {
    const inputValue = event.target.value;
    let value: number | undefined = parseInt(inputValue, 10);
    if (Number.isNaN(value) || value < 0) {
      value = undefined;
    }
    this.updatePipeToActiveValue(u, pipeId, index, value, 'dpd', 'dpd_count');
  }

  handleSave() {
    const { user, saveUserToPipelineRel } = this.props;
    console.log(`saving new settings`);
    const filters = user.filter((u) => u.modified);
    for (const f of filters) {
      delete f.modified;
    }
    saveUserToPipelineRel(filters);
    this.setState({ dataChanged: false });
  }

  handleCancel() {
    console.log(`cancle settings, restoring old state`);
    const { user, loadUser } = this.props;
    loadUser(user.filter((u) => u.modified).map((u) => u.pk));
    this.setState({ dataChanged: false });
  }

  // eslint-disable-next-line class-methods-use-this
  addCognitoUser(event: React.MouseEvent, u: DynamoDBObjects.User) {
    console.log(`adding user '${u.name}' as cognito/sc-dashboard user`);
    // eslint-disable-next-line no-alert
    alert(`Not yet implemented`);
  }

  render() {
    const { dataChanged } = this.state;
    const { classes, user, pipelines } = this.props;

    const pipes = (u: User, index: number) => {
      return pipelines.map((p) => {
        return (
          <TableCell align="right" key={p.pipedrive_id}>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              className={classes['grid-right']}
            >
              <Grid item>
                <Checkbox
                  checked={
                    u.pipeline_to_active[p.pipedrive_id]?.active || false
                  }
                  value={u.pipeline_to_active[p.pipedrive_id]?.active || false}
                  onChange={(e) => {
                    this.handleCheckboxChange(e, u, p.pipedrive_id, index);
                  }}
                />
              </Grid>
              <Grid item>
                <SkipNext />
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    type="number"
                    size="small"
                    label="Skip"
                    margin="dense"
                    style={{ width: '40px' }}
                    value={u.pipeline_to_active[p.pipedrive_id]?.skip || ''}
                    onChange={(e) => {
                      this.handleSkipChange(e, u, p.pipedrive_id, index);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item className={classes['grid-new-item']}>
                <PauseCircleOutline />
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    type="number"
                    size="small"
                    label="DpD"
                    margin="dense"
                    style={{ width: '40px' }}
                    value={u.pipeline_to_active[p.pipedrive_id]?.dpd || ''}
                    onChange={(e) => {
                      this.handleDPDChange(e, u, p.pipedrive_id, index);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </TableCell>
        );
      });
    };
    const list = user
      .filter((u) => !!u)
      .map((u, index) => {
        return (
          <TableRow key={u.pk}>
            {/* <TableCell>
              {!u.cognito_id ? (
                <IconButton
                  title="Account hinzufügen"
                  onClick={e => this.addCognitoUser(e, u)}
                >
                  <Add />
                </IconButton>
              ) : (
                <Check />
              )}
            </TableCell> */}
            <TableCell size="small" className={classes.avatarCol}>
              <div className={classes.avatarContainer}>
                <div className={classes.avatar}>{getInitials(u.name)}</div>
              </div>
            </TableCell>
            <TableCell className={classes.namecol} size="small">
              {u.name}
              <br />
              <span className={classes.hint}>{u.email}</span>
            </TableCell>
            {pipes(u, index)}
          </TableRow>
        );
      });

    const pipelineHeader = pipelines.map((p) => {
      return (
        <TableCell align="right" key={p.pipedrive_id}>
          {p.name} <span className={classes.hint}>({p.pipedrive_id})</span>
        </TableCell>
      );
    });
    return (
      <>
        <NextUserPaper userlist={user} onChange={this.handleNextUserChange} />
        <Paper style={{ paddingRight: '10px', marginTop: '10px' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>Config Acc</TableCell> */}
                  <TableCell size="small" className={classes.avatarCol} />
                  <TableCell className={classes.namecol} size="small">
                    Name
                  </TableCell>
                  {pipelineHeader}
                </TableRow>
              </TableHead>
              <TableBody>{list}</TableBody>
            </Table>
          </TableContainer>
          {dataChanged && (
            <div className={classes['button-area']}>
              <Button variant="contained" onClick={this.handleSave}>
                Speichern
              </Button>
              <Button variant="contained" onClick={this.handleCancel}>
                Abbrechen
              </Button>
            </div>
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: selectors.getActiveUserlist(state),
  pipelines: selectors.getPipelineList(state).filter((p) => p.active),
  company_id: selectors.getCompanyId(state),
});
const mapDispatchToProps = {
  saveUserToPipelineRel: ApiActions.saveUserToPipelineRel,
  loadUser: ApiActions.loadUser,
  updateUser: ReduxActions.updateUser,
};

type Styles = WithStyles<typeof styles>;
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(
  withStyles(styles, { withTheme: true })(ConsultOverview)
);
