import React, { Fragment } from 'react';

export const Home: React.FC = () => {
  return (
    <Fragment>
      <h1>Simple Check Dashboard</h1>
      <p>
        Dashboard um die Verknüpfung der Kampagnen und pipedrive zu steuern.
      </p>
    </Fragment>
  );
};
