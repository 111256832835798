/* eslint no-underscore-dangle: 0, max-classes-per-file: ["error", 2], lines-between-class-members: ["error", "always", { exceptAfterSingleLine: true }] */
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';

export interface FilterExt extends DynamoDBObjects.Filter {
  edit?: boolean;
  field?: Field;
  filterMode?: FilterMode;
  modified?: boolean;
}

export interface ModeOptions {}

export interface TimeframeExtended extends DynamoDBObjects.Timeframe {
  modified?: boolean;
  old_start_date?: number;
}

export interface BasicType {
  id: string;
  label: string;
}

export class Field implements BasicType {
  public static VALUES: Array<Field> = [];

  public static PLZ = new Field('plz', 'Postleitzahl');
  public static JOB = new Field('job', 'Berufsstatus');
  public static INSUR = new Field('insure', 'Versichert');
  public static REF = new Field('referer', 'Referer');
  public static AGE = new Field('age', 'Alter');

  constructor(private _id: string, private _label: string) {
    Field.VALUES.push(this);
  }

  public static getFieldById(id: string) {
    return Field.VALUES.find((f) => f.id === id);
  }

  public get id(): string {
    return this._id;
  }

  public get label(): string {
    return this._label;
  }
}

export class FilterMode implements BasicType {
  // EQUAL = '=',
  // LESS = '<',
  // GREATER = '>',
  // BETWEEN = '$1 > $2 > $3',

  public static VALUES: Array<FilterMode> = [];

  public static EQUAL = new FilterMode('1', '=', 'Gleich (=)');
  public static GREATER = new FilterMode('2', '>', 'Größer (>)');
  public static SMALLER = new FilterMode('3', '<', 'Kleiner (<)');
  public static BETWEEN = new FilterMode('4', '<>', 'Zwischen');
  public static BEGINSWITH = new FilterMode('5', 'beginswidth', 'Beginnt mit');
  public static ENDSWITH = new FilterMode('6', 'endswidth', 'Endet mit');
  public static NOT_EQUAL = new FilterMode('7', '!=', 'Ungleich (!=)');

  private static IdToFM: { [id: string]: FilterMode } = {};

  constructor(
    private _id: string,
    private _mode: string,
    private _label: string,
    private _opts?: ModeOptions
  ) {
    FilterMode.VALUES.push(this);
    if (!FilterMode.IdToFM) FilterMode.IdToFM = {};

    FilterMode.IdToFM[this._id] = this;
  }

  public static getModeById(id: string) {
    return FilterMode.VALUES.find((fm) => fm.id === id);
  }

  public get label(): string {
    return this._label;
  }

  public get id(): string {
    return this._id;
  }

  public get mode(): string {
    return this._mode;
  }

  public get opts(): ModeOptions | undefined {
    return this._opts;
  }
}
