import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import { AnyAction, Dispatch } from 'redux';
import { FilterExt, TimeframeExtended } from '../../../util/interfaces';
import { DYNAMODB_TYPE } from './api';
import { CALL_API } from './middleware';

type Timeframe = TimeframeExtended;

export type UPDATE_TYPE<T> = {
  value: T;
  index: number;
};

export const PIPELINE_REQUEST = 'PIPELINE_REQUEST';
export const PIPELINE_SUCCESS = 'PIPELINE_SUCCESS';
export const PIPELINE_FAILURE = 'PIPELINE_FAILURE';

interface ApiDispatch {
  (action: ApiAction): any;
}

interface ApiAction {
  [CALL_API]: { reqTypes: string[] } & AnyAction;
}

// Fetches pipelines from the dynamodb unless it is cached [NOT IMPLEMENTED].
// Relies on Redux Thunk middleware.
const loadPipelines = () => (dispatch: ApiDispatch) => {
  return dispatch({
    [CALL_API]: {
      reqTypes: [PIPELINE_REQUEST, PIPELINE_SUCCESS, PIPELINE_FAILURE],
      type: DYNAMODB_TYPE.PIPELINES,
    },
  });
};

export const UPDATE_PIPELINE = 'UPDATE_PIPELINE_STATE';

const updatePipeline = (pipeline: DynamoDBObjects.Pipeline, index: number) => (
  disatch: Dispatch
) => {
  return disatch({
    type: UPDATE_PIPELINE,
    payload: {
      value: pipeline,
      index,
    },
  });
};

export const SAVE_PIPELINE_REQUEST = 'SAVE_PIPELINE_REQUEST';
export const SAVE_PIPELINE_SUCCESS = 'SAVE_PIPELINE_SUCCESS';
export const SAVE_PIPELINE_FAILURE = 'SAVE_PIPELINE_FAILURE';

const savePipelines = (pipelines: Array<DynamoDBObjects.Pipeline>) => (
  dispatch: ApiDispatch
) => {
  return dispatch({
    [CALL_API]: {
      reqTypes: [
        SAVE_PIPELINE_REQUEST,
        SAVE_PIPELINE_SUCCESS,
        SAVE_PIPELINE_FAILURE,
      ],
      type: DYNAMODB_TYPE.SAVE_PIPELINE,
      payload: pipelines,
    },
  });
};

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

const saveUserToPipelineRel = (userlist: DynamoDBObjects.User[] = []) => (
  dispatch: ApiDispatch
) => {
  return dispatch({
    [CALL_API]: {
      reqTypes: [SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE],
      type: DYNAMODB_TYPE.SAVE_USER,
      payload: userlist,
    },
  });
};

export const SAVE_FILTER_REQUEST = 'SAVE_FILTER_REQUEST';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_FAILURE = 'SAVE_FILTER_FAILURE';

const saveFilter = (filters: Array<DynamoDBObjects.Filter>) => (
  dispatch: ApiDispatch
) => {
  return dispatch({
    [CALL_API]: {
      reqTypes: [SAVE_FILTER_REQUEST, SAVE_FILTER_SUCCESS, SAVE_FILTER_FAILURE],
      type: DYNAMODB_TYPE.SAVE_FILTERS,
      payload: filters,
    },
  });
};

export const LOAD_FILTER_REQUEST = 'LOAD_FILTER_REQUEST';
export const LOAD_FILTER_SUCCESS = 'LOAD_FILTER_SUCCESS';
export const LOAD_SPECIFIC_FILTER_SUCCESS = 'LOAD_SPECIFIC_FILTER_SUCCESS';
export const LOAD_FILTER_FAILURE = 'LOAD_FILTER_FAILURE';

const loadFilter = (ids?: number[]) => (dispatch: ApiDispatch) => {
  return dispatch({
    [CALL_API]: {
      reqTypes: [
        LOAD_FILTER_REQUEST,
        ids && ids.length > 0
          ? LOAD_SPECIFIC_FILTER_SUCCESS
          : LOAD_FILTER_SUCCESS,
        // LOAD_FILTER_SUCCESS,
        LOAD_FILTER_FAILURE,
      ],
      type: DYNAMODB_TYPE.LOAD_FILTERS,
      payload: ids,
    },
  });
};

export const UPDATE_FILTER = 'UPDATE_FILTER_STATE';

const updateFilter = (
  filter: DynamoDBObjects.Filter | FilterExt,
  index: number
) => (dispatch: Dispatch) => {
  return dispatch({
    type: UPDATE_FILTER,
    payload: {
      value: filter,
      index,
    },
  });
};

export const DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST';
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';
export const DELETE_FILTER_FAILURE = 'DELETE_FILTER_FAILURE';

const deleteFilter = (filterIds: number[]) => (disatch: ApiDispatch) => {
  return disatch({
    [CALL_API]: {
      reqTypes: [
        DELETE_FILTER_REQUEST,
        DELETE_FILTER_SUCCESS,
        DELETE_FILTER_FAILURE,
      ],
      type: DYNAMODB_TYPE.DELETE_FILTERS,
      payload: filterIds,
      successInfo: {
        count: filterIds.length,
      },
    },
  });
};

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_SPECIFIC_USER_SUCCESS = 'LOAD_SPECIFIC_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

const loadUser = (ids: string[]) => (disatch: ApiDispatch) => {
  return disatch({
    [CALL_API]: {
      reqTypes: [
        LOAD_USER_REQUEST,
        ids && ids.length > 0 ? LOAD_SPECIFIC_USER_SUCCESS : LOAD_USER_SUCCESS,
        LOAD_USER_FAILURE,
      ],
      type: DYNAMODB_TYPE.LOAD_USER,
      payload: ids,
    },
  });
};

export const UPDATE_USER = 'UPDATE_USER_STATE';

const updateUser = (user: DynamoDBObjects.User, index: number) => (
  disatch: Dispatch
) => {
  return disatch({
    type: UPDATE_USER,
    payload: {
      value: user,
      index,
    },
  });
};

export const LOAD_TIMEFRAME_REQUEST = 'LOAD_TIMEFRAME_REQUEST';
export const LOAD_TIMEFRAME_SUCCESS = 'LOAD_TIMEFRAME_SUCCESS';
export const LOAD_TIMEFRAME_INIT_SUCCESS = 'LOAD_TIMEFRAME_INIT_SUCCESS';
export const LOAD_SPECIFIC_TIMEFRAME_SUCCESS =
  'LOAD_SPECIFIC_TIMEFRAME_SUCCESS';
export const LOAD_TIMEFRAME_FAILURE = 'LOAD_TIMEFRAME_FAILURE';

const loadTimeframes = (ids?: number[] | boolean) => (
  dispatch: ApiDispatch
) => {
  let success = LOAD_TIMEFRAME_SUCCESS;
  if (ids && Array.isArray(ids) && ids.length > 0) {
    success = LOAD_SPECIFIC_TIMEFRAME_SUCCESS;
  } else if (typeof ids === 'boolean' && ids) {
    success = LOAD_TIMEFRAME_INIT_SUCCESS;
  }
  return dispatch({
    [CALL_API]: {
      reqTypes: [LOAD_TIMEFRAME_REQUEST, success, LOAD_TIMEFRAME_FAILURE],
      type: DYNAMODB_TYPE.LOAD_TIMEFRAMES,
      payload: ids,
    },
  });
};

export const SAVE_TIMEFRAME_REQUEST = 'SAVE_TIMEFRAME_REQUEST';
export const SAVE_TIMEFRAME_SUCCESS = 'SAVE_TIMEFRAME_SUCCESS';
export const SAVE_TIMEFRAME_FAILURE = 'SAVE_TIMEFRAME_FAILURE';

const saveTimeframe = (frames: Array<DynamoDBObjects.Timeframe>) => (
  dispatch: ApiDispatch
) => {
  return dispatch({
    [CALL_API]: {
      reqTypes: [
        SAVE_TIMEFRAME_REQUEST,
        SAVE_TIMEFRAME_SUCCESS,
        SAVE_TIMEFRAME_FAILURE,
      ],
      type: DYNAMODB_TYPE.SAVE_TIMEFRAMES,
      payload: frames,
    },
  });
};

export const UPDATE_TIMEFRAME = 'UPDATE_TIMEFRAME_STATE';

const updateTimeframe = (frame: Timeframe) => (disatch: Dispatch) => {
  return disatch({
    type: UPDATE_TIMEFRAME,
    payload: {
      value: frame,
    },
  });
};

export const DELETE_TIMEFRAME_REQUEST = 'DELETE_TIMEFRAME_REQUEST';
export const DELETE_TIMEFRAME_SUCCESS = 'DELETE_TIMEFRAME_SUCCESS';
export const DELETE_TIMEFRAME_FAILURE = 'DELETE_TIMEFRAME_FAILURE';

const deleteTimeframe = (timeframeIds: number[]) => (disatch: ApiDispatch) => {
  return disatch({
    [CALL_API]: {
      reqTypes: [
        DELETE_TIMEFRAME_REQUEST,
        DELETE_TIMEFRAME_SUCCESS,
        DELETE_TIMEFRAME_FAILURE,
      ],
      type: DYNAMODB_TYPE.DELETE_TIMEFRAMES,
      payload: timeframeIds,
      successInfo: {
        count: timeframeIds.length,
      },
    },
  });
};

export const UPDATE_INIT_DATA = 'UPDATE_INIT_DATA';

const updateInitData = (
  data: [
    Array<DynamoDBObjects.User>,
    Array<DynamoDBObjects.Pipeline>,
    Array<DynamoDBObjects.Filter>,
    Array<DynamoDBObjects.Timeframe>,
    Array<DynamoDBObjects.Timeframetype>
  ]
) => ({
  type: UPDATE_INIT_DATA,
  payload: data,
});

export const ApiActions = {
  loadPipelines,
  savePipelines,
  loadUser,
  saveUserToPipelineRel,
  loadFilter,
  saveFilter,
  deleteFilter,
  loadTimeframes,
  saveTimeframe,
  deleteTimeframe,
};

export const ReduxActions = {
  updatePipeline,
  updateFilter,
  updateUser,
  updateTimeframe,
  updateInitData,
};
