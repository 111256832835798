import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../features/store';
import { selectors } from '../../features/pipedrive';
import { getNextUser } from '../../util/nextuser';
import { getInitials } from '../../util';
import { ApiActions } from '../../features/aws/dynamodb/actions';
import { DynamoDBObjects, State } from '@maom/aws-dynamodb-interfaces';
import { User } from './Consult-Overview';

const useStyles = makeStyles({
  hint: {
    fontSize: '0.9em',
    opacity: '0.7',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  avatar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
  },
  avatarContainer: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    width: '48px',
    borderRadius: '50%',
    height: '48px',
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  'next-user-container': {
    padding: '10px 0',
    marginTop: '10px',
    marginBottom: '20px',
    minWidth: '250px',
    width: '60%',
    '& .box': {
      marginBottom: '12px',
      marginLeft: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .header': {
      '& .pipeline-select': {
        minWidth: '100px',
      },
      '& .header-title': {
        marginRight: '10px',
      },
    },
    '& div.user': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '& div:first-child': {
        marginRight: '8px',
      },
    },
    '& .skip': {
      '& p:first-child': {
        marginRight: '8px',
      },
    },
    ' & .skip-button': {
      marginLeft: '30px',
      '& button': {
        fontWeight: 400,
      },
    },
  },
});

function recalcState(state: initialNextUserState): initialNextUserState {
  const nextState = { ...state };
  if (state.pipeline_id > -1) {
    nextState.next_user = getNextUser(
      state.userlist,
      {
        'e-mail': '',
        anrede: '',
        company_id: state.company_id + '',
        pipeline_id: state.pipeline_id,
        nachname: '',
        telefon: '',
        vorname: '',
      },
      [],
      state.timeframes || []
    ).user;
    nextState.user_opts = state.userlist
      .filter(
        (u) =>
          u.pipedrive_id !== nextState.next_user?.pipedrive_id &&
          state.pipeline_id in u.pipeline_to_active &&
          u.pipeline_to_active[state.pipeline_id] &&
          u.pipeline_to_active[state.pipeline_id].active
      )
      .map((u) => (
        <MenuItem key={u.pipedrive_id} value={u.pipedrive_id}>
          {u.name}
        </MenuItem>
      ));
  } else {
    nextState.user_opts = [];
  }

  return nextState;
}

type UserReducerActions =
  | 'updateSkipToUserId'
  | 'updatePipeline'
  | 'userlistUpdate'
  | 'updateNextUser';
type initialNextUserState = {
  company_id: number;
  pipeline_id: number;
  next_user: DynamoDBObjects.User | undefined;
  skip_to_user_id: number;
  user_opts: JSX.Element[];
  userlist: DynamoDBObjects.User[];
  timeframes: DynamoDBObjects.Timeframe[];
};

function nextUserReducer(
  state: initialNextUserState,
  action: { type: UserReducerActions; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case 'updateSkipToUserId':
      return { ...state, skip_to_user_id: payload.user_id };
    case 'updateNextUser':
      return { ...state, next_user: payload.user };
    case 'userlistUpdate': {
      const nextState = { ...state, userlist: payload.list };
      return recalcState(nextState);
    }
    case 'updatePipeline': {
      const nextState = {
        ...state,
        pipeline_id: payload.pipelineId,
        skip_to_user_id: -1,
      };
      return recalcState(nextState);
    }
    default:
      return state;
  }
}

type Props = {
  onChange: (changes: { user: DynamoDBObjects.User; index: number }[]) => void;
  userlist: DynamoDBObjects.User[];
} & ReduxProps;

const NextUserPaper = ({
  onChange,
  pipelines,
  userlist,
  timeframes,
  company_id,
}: Props) => {
  const [nextUserState, dispatchNextUserAction] = useReducer(nextUserReducer, {
    company_id,
    pipeline_id: -1,
    next_user: undefined,
    skip_to_user_id: -1,
    user_opts: [],
    userlist,
    timeframes: timeframes || [],
  });
  const classes = useStyles();

  useEffect(() => {
    if (Array.isArray(userlist))
      dispatchNextUserAction({
        type: 'userlistUpdate',
        payload: { list: userlist },
      });
  }, [userlist]);

  const pipelineOpts = useMemo(() => {
    return pipelines.map((p) => (
      <MenuItem key={p.pipedrive_id} value={p.pipedrive_id}>
        {p.name}
      </MenuItem>
    ));
  }, [pipelines]);

  const handlePipelineChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const pipeID = e.target.value as number;
    dispatchNextUserAction({
      type: 'updatePipeline',
      payload: {
        pipelineId: pipeID,
      },
    });
  };

  const updateNextUserState = () => {
    userlist
      .filter(
        (u) =>
          u.pipeline_to_active &&
          nextUserState.pipeline_id in u.pipeline_to_active &&
          (u.pipeline_to_active[nextUserState.pipeline_id].state || 0) >=
            State.AWAITING
      )
      .forEach(
        (u) =>
          (u.pipeline_to_active[nextUserState.pipeline_id].state =
            (u.pipeline_to_active[nextUserState.pipeline_id].state ||
              State.AWAITING) - State.AWAITING)
      );
    const changes = [];
    for (let i = 0; i < userlist.length; i++) {
      const u: User = userlist[i];
      console.log(
        u.name,
        u.pipeline_to_active[nextUserState.pipeline_id].state
      );
      if (u.pipedrive_id === nextUserState.skip_to_user_id) {
        u.pipeline_to_active[nextUserState.pipeline_id] = {
          ...u.pipeline_to_active[nextUserState.pipeline_id],
          state:
            (u.pipeline_to_active[nextUserState.pipeline_id].state || 0) +
            State.AWAITING,
        };

        u.modified = true;
        changes.push({ user: u, index: i });
        dispatchNextUserAction({
          type: 'updatePipeline',
          payload: {
            pipelineId: nextUserState.pipeline_id,
          },
        });
      } else if (
        (u.pipeline_to_active[nextUserState.pipeline_id].state || 0) >=
        State.AWAITING
      ) {
        u.pipeline_to_active[nextUserState.pipeline_id].state =
          (u.pipeline_to_active[nextUserState.pipeline_id].state ||
            State.AWAITING) - State.AWAITING;
        u.modified = true;
        changes.push({ user: u, index: i });
        dispatchNextUserAction({
          type: 'updatePipeline',
          payload: {
            pipelineId: nextUserState.pipeline_id,
          },
        });
      }
    }
    if (changes.length > 0) {
      onChange(changes);
    }
  };

  return (
    <Paper className={classes['next-user-container']}>
      <div className="box header">
        <div className="header-title">
          Nächster Berater in Pipeline
          <span className={classes.hint}> (ohne Filter)</span>:
        </div>
        <div>
          <Select
            className="pipeline-select"
            value={nextUserState.pipeline_id}
            onChange={handlePipelineChange}
          >
            <MenuItem disabled value={-1}>
              Pipeline auswählen
            </MenuItem>
            {pipelineOpts}
          </Select>
        </div>
      </div>
      <div className="box user">
        {nextUserState.next_user && (
          <Fragment>
            <div className={classes.avatarContainer}>
              <div className={classes.avatar}>
                {getInitials(nextUserState.next_user?.name || 'U')}
              </div>
            </div>
            <div>
              {nextUserState.next_user?.name}
              <br />
              <span className={classes.hint}>
                {nextUserState.next_user?.email}
              </span>
            </div>
          </Fragment>
        )}
        {!nextUserState.next_user && nextUserState.pipeline_id > -1 && (
          <Typography>Kein Berater gefunden</Typography>
        )}
      </div>
      {nextUserState.next_user && nextUserState.pipeline_id > -1 && (
        <div className="box skip">
          <Typography>Überspringen zu:</Typography>
          <Select
            className="pipeline-select"
            value={nextUserState.skip_to_user_id}
            onChange={(e) =>
              dispatchNextUserAction({
                type: 'updateSkipToUserId',
                payload: { user_id: e.target.value as number },
              })
            }
          >
            <MenuItem disabled value={-1}>
              User auswählen
            </MenuItem>
            {nextUserState.user_opts}
          </Select>

          <div className="skip-button">
            <Button
              disabled={nextUserState.skip_to_user_id === -1}
              onClick={updateNextUserState}
              variant="outlined"
            >
              Überspringen
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({
  pipelines: selectors.getPipelineList(state).filter((p) => p.active),
  timeframes: selectors.getTimeframes(state),
  company_id: selectors.getCompanyId(state),
});

const mapDispatchToProps = {
  saveUserToPipelineRel: ApiActions.saveUserToPipelineRel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(NextUserPaper);
