import {
  createStyles,
  DialogTitle as MuiDialogTitle,
  IconButton,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FC } from 'react';

interface TableHeaderProps {
  list: Array<{ name: string } & Partial<TableCellProps>>;
}

export const TableHeader: FC<TableHeaderProps> = ({
  list,
  ...rest
}: TableHeaderProps) => {
  let cells: Array<JSX.Element> = [];
  if (list) {
    let i = 0;

    cells = list.map(({ name, ...cellProps }) => (
      /* eslint-disable-next-line  no-plusplus */
      <TableCell key={i++} {...cellProps}>
        {name}
      </TableCell>
    ));
  }

  return (
    <TableHead {...rest}>
      <TableRow>{cells}</TableRow>
    </TableHead>
  );
};

const dialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: '#0088D7',
      color: '#fff',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#fff',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
  id?: string;
  children: React.ReactText | React.ReactText[];
  onClose: () => void;
}

export const DialogTitle = withStyles(dialogTitleStyles, { withTheme: true })(
  (props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);
