import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import {
  DELETE_FILTER_SUCCESS,
  DELETE_TIMEFRAME_SUCCESS,
  LOAD_FILTER_SUCCESS,
  LOAD_SPECIFIC_FILTER_SUCCESS,
  LOAD_SPECIFIC_USER_SUCCESS,
  LOAD_TIMEFRAME_INIT_SUCCESS,
  LOAD_TIMEFRAME_SUCCESS,
  SAVE_FILTER_SUCCESS,
  UPDATE_FILTER,
  UPDATE_INIT_DATA,
  UPDATE_PIPELINE,
  UPDATE_TIMEFRAME,
  UPDATE_USER,
} from '../aws/dynamodb/actions';
import { FilterExt } from '../../util/interfaces';
import { MiddlewareAPIResponse } from '../aws/dynamodb/middleware';

export const RESET_CONSULT = 'RESET_CONSULT';

interface UpdateConsultAction {
  type:
    | typeof UPDATE_INIT_DATA
    | typeof SAVE_FILTER_SUCCESS
    | typeof LOAD_FILTER_SUCCESS
    | typeof LOAD_TIMEFRAME_SUCCESS
    | typeof LOAD_TIMEFRAME_INIT_SUCCESS
    | typeof UPDATE_TIMEFRAME
    | typeof LOAD_SPECIFIC_FILTER_SUCCESS
    | typeof UPDATE_FILTER
    | typeof DELETE_FILTER_SUCCESS
    | typeof LOAD_SPECIFIC_USER_SUCCESS
    | typeof UPDATE_USER
    | typeof DELETE_TIMEFRAME_SUCCESS
    | typeof UPDATE_PIPELINE
    | typeof RESET_CONSULT;
  response?: MiddlewareAPIResponse;
  payload?: any;
}

export type ConsultActionTypes = UpdateConsultAction;

export interface ConsultState {
  users: Array<DynamoDBObjects.User>;
  pipelines: Array<DynamoDBObjects.Pipeline>;
  filters: Array<FilterExt>;
  timeframes: Array<DynamoDBObjects.Timeframe> | undefined;
  timeframetypes: Array<DynamoDBObjects.Timeframetype>;
}

export interface LoadSpecificTypeResponse<T> {
  ids: number[];
  value: { [key: number]: T | undefined };
}

export interface LoadSpecificTypeResponseS<T> {
  ids: string[];
  value: { [key: string]: T | undefined };
}
