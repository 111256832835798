import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { UserAttributes } from './aws/cognito/interfaces';
import DynamoDBAPI from './aws/dynamodb/api';
import { DynamoDBApi } from './aws/dynamodb/middleware';
import { ConsultReducer } from './pipedrive';
import { ConsultInitState } from './pipedrive/consultReducer';
import msgReducer from './util/message-reducer';
import { RESET_USER, UserReducer } from './userReducer';
import { Actions, defineAbilityFor, guestUserAbility, Subjects } from '../casl';
import { LoadSpecificTypeResponseS, RESET_CONSULT } from './pipedrive/types';
import { RESET_MESSAGE } from './util/message-action';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  consult: ConsultReducer,
  user: UserReducer,
  messages: msgReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

async function loadCognitoUserAttr(): Promise<UserAttributes> {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

  return new Promise((res, rej) => {
    cognitoUser.getUserAttributes(
      (error, userattributes?: CognitoUserAttribute[]) => {
        if (error) rej(error);
        else if (Array.isArray(userattributes)) {
          const result: UserAttributes = userattributes.reduce(
            (map, ua) => ({
              ...map,
              [ua.getName()]: ua.getValue(),
            }),
            {} as UserAttributes
          );

          res(result);
        }
      }
    );
  });
}

export const loadInitDdata = async (
  loadUser: boolean,
  loadPipelines: boolean,
  loadFilters: boolean,
  loadTimeframes: boolean,
  loadTimeframetypes: boolean
): Promise<
  [
    Array<DynamoDBObjects.User>,
    Array<DynamoDBObjects.Pipeline>,
    Array<DynamoDBObjects.Filter>,
    Array<DynamoDBObjects.Timeframe>,
    Array<DynamoDBObjects.Timeframetype>
  ]
> => {
  const proms: Array<Promise<
    | Array<DynamoDBObjects.User>
    | Array<DynamoDBObjects.Pipeline>
    | Array<DynamoDBObjects.Filter>
    | Array<DynamoDBObjects.Timeframe>
    | Array<DynamoDBObjects.Timeframetype>
  >> = [
    loadUser
      ? (DynamoDBAPI.getUserlist() as Promise<DynamoDBObjects.User[]>)
      : Promise.resolve([]),
    loadPipelines ? DynamoDBAPI.getPipelines() : Promise.resolve([]),
    loadFilters
      ? (DynamoDBAPI.getFilters() as Promise<DynamoDBObjects.Filter[]>)
      : Promise.resolve([]),
    loadTimeframes
      ? (DynamoDBAPI.getTimeframes() as Promise<DynamoDBObjects.Timeframe[]>)
      : Promise.resolve([]),
    loadTimeframetypes ? DynamoDBAPI.loadTimeframeTypes() : Promise.resolve([]),
  ];

  return (await Promise.all<
    Array<DynamoDBObjects.User>,
    Array<DynamoDBObjects.Pipeline>,
    Array<DynamoDBObjects.Filter>,
    Array<DynamoDBObjects.Timeframe>,
    Array<DynamoDBObjects.Timeframetype>
    // @ts-ignore
  >(proms)
    .catch((e: Error) => {
      console.error(`loading init store state`);
      console.error(e);
      return proms;
    })
    .then(async (u: Array<any>) => {
      if (u.every(Array.isArray)) {
        return u;
      }

      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const r of u) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const v = await r;
          result.push(v);
        } catch (e) {
          result.push([]);
        }
      }
      return result;
    })) as [
    Array<DynamoDBObjects.User>,
    Array<DynamoDBObjects.Pipeline>,
    Array<DynamoDBObjects.Filter>,
    Array<DynamoDBObjects.Timeframe>,
    Array<DynamoDBObjects.Timeframetype>
  ];
};

export const getCognitoUser = async () => {
  const attr: UserAttributes = await loadCognitoUserAttr();
  DynamoDBAPI.updateCompanyId(attr['custom:pipedrive_company_id']);
  return attr;
};

export const resetStore = (store: Store) => {
  store.dispatch({ type: RESET_CONSULT });
  store.dispatch({ type: RESET_MESSAGE });
  store.dispatch({ type: RESET_USER });
};

export async function loadData(cognitoUser: UserAttributes) {
  const loadSpecificUserRes = (await DynamoDBAPI.getUserlist({
    cognito_id: cognitoUser.sub,
  })) as LoadSpecificTypeResponseS<DynamoDBObjects.User>;

  let ability = guestUserAbility;
  let dynamodbUser: DynamoDBObjects.User | undefined = undefined;
  if (
    loadSpecificUserRes &&
    loadSpecificUserRes.value &&
    Object.keys(loadSpecificUserRes.value).length === 1
  ) {
    dynamodbUser = Object.values(loadSpecificUserRes.value)[0];

    if (dynamodbUser != null) {
      console.log(
        'using user abilities',
        dynamodbUser.pipedrive_id,
        dynamodbUser.name,
        dynamodbUser.role
      );
      try {
        ability = defineAbilityFor(dynamodbUser);
      } catch (e) {
        console.error(
          'define Ability for user: ',
          dynamodbUser.pipedrive_id,
          dynamodbUser.name
        );
        console.error(e);
        ability = guestUserAbility;
      }
    } else {
      console.log('using guest abilities');
    }
  } else {
    console.log('using guest abilities', 'could not load cognito user');
  }

  const [
    users,
    pipelines,
    filters,
    timeframes,
    timeframetypes,
  ] = await loadInitDdata(
    ability.can(Actions.SEE, Subjects.USER_LIST),
    ability.can(Actions.SEE, Subjects.ALL_PIPELINES),
    ability.can(Actions.SEE, Subjects.ALL_FILTER),
    ability.can(Actions.SEE, Subjects.ALL_TIMEFRAMES),
    ability.can(Actions.SEE, Subjects.ALL_TIMEFRAME_TYPES)
  );
  return {
    users,
    pipelines,
    filters,
    timeframes,
    timeframetypes,
    ability,
    dynamodbUser,
  };
}

export const configureStore = async () => {
  const initState: any = {
    consult: ConsultInitState,
    user: null,
    messages: {},
  };
  let ab = guestUserAbility;

  try {
    // console.log(AWS.config);

    DynamoDBAPI.init();
    const attr = await getCognitoUser();
    const {
      users,
      pipelines,
      filters,
      timeframetypes,
      ability,
      dynamodbUser,
    } = await loadData(attr);
    ab = ability;

    if (dynamodbUser) initState.user = dynamodbUser;

    initState.consult.users = users;
    initState.consult.pipelines = pipelines;
    initState.consult.filters = filters;
    initState.consult.timeframetypes = timeframetypes;
  } catch (e) {
    console.error(`loading init store state`);
    console.error(e);
  }

  const composeEnhancers =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return {
    store: createStore(
      rootReducer,
      initState,
      composeEnhancers(applyMiddleware(thunk, DynamoDBApi))
    ),
    ab,
  };
};
