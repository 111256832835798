import React, { useMemo } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { shallowEqual, useSelector } from 'react-redux';
import { selectors } from '../../features/pipedrive';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from "@material-ui/core/styles";

const useStyles=makeStyles({
  'user': {
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px',
    justifyContent: 'space-around',
    '& svg.ui-icon': {
      fontSize: '2rem',
      color: '#78909C',
      margin: '0 8px',
      '&.person': {
        fontSize: '2.2rem',
      },
    },
  },
}, {name: 'user-select'})

function UserSelect() {
  const { control } = useFormContext();
  const styles = useStyles();
  const userlist = useSelector(selectors.getActiveUserlist, shallowEqual);

  const userlistOptions = useMemo(() => {
    const opts = [];
    opts.push(
      <MenuItem disabled key={-1} value={-1}>
        User wählen*
      </MenuItem>
    );
    opts.push(
      ...userlist.map((u) => (
        <MenuItem key={u.pipedrive_id} value={u.pipedrive_id}>
          {u.name}
        </MenuItem>
      ))
    );
    return opts;
  }, [userlist]);

  return (
    <div className={styles.user}>
      <Person className={`ui-icon person`} />
      <Controller
        name="userid"
        as={
          <Select placeholder="User" margin="none">
            {userlistOptions}
          </Select>
        }
        control={control}
      />
    </div>
  );
}

export default UserSelect;
