import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, ReactElement, useState } from 'react';
import { MessageType } from '../../features/util/message-action';

export interface MSProps {
  type: MessageType;
  msg?: string;
  reset?: (type: MessageType) => void;
}

export function MessageSnackbar({ type, msg, reset }: MSProps): ReactElement {
  const [open, setOpen] = useState(true);
  return (
    <Fragment>
      {msg && msg !== '' && (
        <div>
          <Snackbar
            open={open}
            onClose={() => {
              setOpen(false);
              if (reset) reset(type);
            }}
            autoHideDuration={type === MessageType.SUCCESS ? 6000 : null}
          >
            <Alert
              severity={type}
              action={
                reset && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => reset && reset(type)}
                  >
                    OK
                  </Button>
                )
              }
            >
              <span style={{ whiteSpace: 'pre' }}>{msg}</span>
            </Alert>
          </Snackbar>
        </div>
      )}
    </Fragment>
  );
}
