import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Hub, I18n } from 'aws-amplify';
import { connect, ConnectedProps, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AbilityContext } from './casl/CaslCan';
import App from './App';
import { MessageSnackbar } from './components/util/MessageSnackbar';
import { configureStore, RootState } from './features/store';
import {
  MessageType,
  resetMessageAction,
} from './features/util/message-action';
import './index.css';
import * as serviceWorker from './serviceWorker';
import awsconfig from './aws-exports';
import { logger } from './util';
import Hublistener from './util/hublistener';
import GreetingsBar from './components/appbar/GreetingsBar';
import './i18n/translation';

Hub.listen('auth', Hublistener.listener());

I18n.configure({ language: 'de' });
Amplify.configure(awsconfig);

serviceWorker.register({
  onUpdate: (res: ServiceWorkerRegistration) => {
    setTimeout(() => {
      res.waiting?.postMessage({ type: 'SKIP_WAITING' });
    }, 2500);
    logger.info('new version is installing 😀');
    ReactDOM.render(
      <MessageSnackbar
        type={MessageType.INFO}
        msg={`Neue Version wird installiert v${process.env.REACT_APP_VERSION}`}
      />,
      document.getElementById('snack')
    );
    // Ensure refresh is only called once.
    // This works around a bug in "force update on reload"
    let refreshing: boolean;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) return;
      refreshing = true;
      setTimeout(() => {
        window.location.reload();
      }, 200);
    });
  },
});

const t = createMuiTheme({
  typography: {
    allVariants: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: { main: '#0088D7' },
  },
});

interface Props extends ReduxProps {
  store: any;
  ability: any;
}

const IndexComp = (props: Props) => {
  const { error, success, resetMessage, store } = props;

  return (
    <ThemeProvider theme={t}>
      <Provider store={store}>
        <div style={{ display: 'flex', paddingTop: '84px' }}>
          <BrowserRouter>
            <AbilityContext.Provider value={props.ability}>
              <GreetingsBar />
              <App />
            </AbilityContext.Provider>
          </BrowserRouter>
        </div>

        <MessageSnackbar
          msg={error}
          type={MessageType.ERROR}
          reset={resetMessage}
        />
        <MessageSnackbar
          msg={success}
          type={MessageType.SUCCESS}
          reset={resetMessage}
        />
      </Provider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: state.messages.error,
  success: state.messages.success,
});

const mapDispatchToProps = {
  resetMessage: resetMessageAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const Index = connector(IndexComp);

configureStore().then(({ store, ab: ability }) => {
  Hublistener.setStore(store);
  ReactDOM.render(
    <Index store={store} ability={ability} />,
    document.getElementById('root')
  );
});
