import {
  DELETE_FILTER_SUCCESS,
  LOAD_TIMEFRAME_INIT_SUCCESS,
  SAVE_FILTER_SUCCESS,
  SAVE_TIMEFRAME_SUCCESS,
  SAVE_USER_SUCCESS,
} from '../aws/dynamodb/actions';
import {
  MessageReducerAction,
  MessageType,
  RESET_MESSAGE,
  UPDATE_MESSAGE,
} from './message-action';

type MessageState = {
  [key in MessageType]?: string;
};

function getMessageForType(type: string, action?: MessageReducerAction) {
  let msg: string | undefined = '';
  switch (type) {
    case RESET_MESSAGE:
      msg = undefined;
      break;

    case SAVE_FILTER_SUCCESS:
      msg = 'Filter erfolgreich gespeichert';
      break;

    case SAVE_USER_SUCCESS:
      msg = 'Berater erfolgreich gespeichert';
      break;

    case LOAD_TIMEFRAME_INIT_SUCCESS:
      msg = undefined;
      break;

    case SAVE_TIMEFRAME_SUCCESS:
      msg = 'Timeframe(s) erfolgreich gespeichert';
      break;

    case DELETE_FILTER_SUCCESS:
      if (action && action.successInfo && action.successInfo.count != null) {
        msg = `Löschen von '${action.successInfo.count}' Filter erfolgreich`;
      } else {
        msg = 'Löschen der Filter erfolgreich';
      }
      break;

    default:
      break;
  }
  return msg ? `${msg}\n` : msg;
}

// Updates error message to notify about the failed fetches.
const messageReducer = (
  state: MessageState = {},
  action: MessageReducerAction
) => {
  const { type, type2 } = action;
  let { msg } = action;
  switch (type) {
    case UPDATE_MESSAGE: {
      if (type2) {
        const msgForType = getMessageForType(type2, action);
        if (msgForType === undefined) return state;
        msg = msgForType + (msg || '');
      }
      return { ...state, [action.msg_type]: msg };
    }
    case RESET_MESSAGE:
      return { ...state, [action.msg_type]: undefined };
    default:
      return state;
  }
};

export default messageReducer;
