// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:a9f60633-8f13-4801-9530-0733330e89e6',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_tqczA1Uuh',
  aws_user_pools_web_client_id: '59jvqh7l6n70bt99bum7msa2d',
  oauth: {},
};

export default awsmobile;
