import {
  Button,
  createStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FormEvent, ReactElement, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApiActions, ReduxActions } from '../../features/aws/dynamodb/actions';
import { selectors } from '../../features/pipedrive';
import { RootState } from '../../features/store';

interface Props extends Styles, ReduxProps {}

const styles = createStyles({
  'pipeline-table': {
    width: '450px',
    padding: '8px 8px 10px 10px',
  },
  'button-area': {
    padding: '4px',
    margin: '4px',
    '& button': {
      marginLeft: '10px',
    },
  },
  container: {
    maxHeight: '310px',
  },
  hint: {
    fontSize: '0.9em',
    opacity: '0.7',
  },
});

function PipelineSettings({
  pipelines,
  classes,
  updatePipeline,
  savePipelines,
}: Props): ReactElement {
  const [dataChanged, setDataChanged] = useState(false);
  const [pipesActive, setPipesActive] = useState(
    pipelines.map(p => p.active || false)
  );
  const basePipeState: boolean[] = useMemo(
    () => pipelines.map(p => p.active || false),
    [pipelines]
  );

  const handleCancel = () => {
    setDataChanged(false);
    setPipesActive(basePipeState);
  };

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDataChanged(false);
    const modifiedIndex = pipelines.map((p, index) => {
      if (p.active !== pipesActive[index]) return index;
      return undefined;
    });
    const updatedPipelines = pipelines.map((p, index) => {
      const pipes = { ...p };
      pipes.active = pipesActive[index];
      return pipes;
    });
    const modifiedPipelines = updatedPipelines.filter((p, index) => {
      return modifiedIndex.includes(index);
    });

    savePipelines(modifiedPipelines);
    modifiedIndex.forEach(i => {
      if (i != null) updatePipeline(updatedPipelines[i], i);
    });
  };

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    index: number
  ) => {
    const newState = [...pipesActive];
    newState[index] = checked;
    setPipesActive(newState);
    setDataChanged(basePipeState.join(',') !== newState.join(','));
  };

  return (
    <Paper className={classes['pipeline-table']}>
      <Typography>
        Aktive Kampangen innerhalb des Dashboards steuern.
      </Typography>
      <Typography className={classes.hint} component="span">
        Nur aktive Kampangen sind zb. in Filtern auswählbar.
      </Typography>
      <form onSubmit={handleSave}>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Aktiv</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pipelines.map((p, index) => (
                <TableRow key={p.pipedrive_id}>
                  <TableCell>{p.name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={pipesActive[index]}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        handleSwitchChange(event, checked, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataChanged && (
          <div className={classes['button-area']}>
            <Button variant="contained" type="submit">
              Speichern
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Abbrechen
            </Button>
          </div>
        )}
      </form>
    </Paper>
  );
}

const mapStateToProps = (state: RootState) => ({
  users: selectors.getActiveUserlist(state),
  pipelines: selectors.getPipelineList(state),
});
const mapDispatchToProps = {
  savePipelines: ApiActions.savePipelines,
  updatePipeline: ReduxActions.updatePipeline,
};

type Styles = WithStyles<typeof styles>;
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(
  withStyles(styles, { withTheme: true })(PipelineSettings)
);
