import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Cancel,
  Check,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Edit,
} from '@material-ui/icons';
import { DynamoDBObjects, State } from '@maom/aws-dynamodb-interfaces';
import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  Fragment,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { selectors } from '../../../features/pipedrive';
import { RootState } from '../../../features/store';
import { BasicType, Field, FilterMode } from '../../../util/interfaces';

type Filter = DynamoDBObjects.Filter;

interface Props extends ReduxProps {
  filter: Filter;
  onSave?: (f: Filter) => void;
  onCancel: () => void;
  deleteMode: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      'align-items': 'center',
    },
    mr: {
      marginRight: '10px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 100,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    'inputs-container': {
      'flex-grow': 1,
    },
    inputs: {
      flexGrow: 1,
      '& > div': {
        margin: theme.spacing(1),
        width: 180,
      },
    },
    'active-icon': {
      marginLeft: '8px',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Function Component, die sich um die Bearbeitung eines Filter kümmert.
 * @param props
 */
function FilterElement({
  filter,
  onSave,
  onCancel,
  userlist,
  pipelinelist,
  deleteMode,
}: Props): ReactElement {
  // Is the componentn in an editable state, or does it only show the saved/changed values
  const [editable, setEditable] = useState(false);

  // which form field to compare
  const [fieldId, setFieldId] = useState<string>('');
  const field = useCallback(() => {
    return Field.getFieldById(fieldId);
  }, [fieldId]);

  // which filter mode to use to compare
  const [filterModeId, setFilterModeId] = useState<string>('');
  const filterMode = useCallback(() => {
    return FilterMode.getModeById(filterModeId);
  }, [filterModeId]);
  // what are the value(s)
  const [value, setValue] = useState<string | number>('');
  const [value2, setValue2] = useState<string | number>('');

  // list of pipedrive userids, who this filter applys to
  const [userIds, setUserIds] = useState<Array<number>>([]);

  // for which pipedrive pipeline is this filter valid
  const [pipelineId, setPipelineId] = useState(0);
  const pipeline = useCallback(() => {
    return pipelinelist.find((p) => p.pipedrive_id === pipelineId);
  }, [pipelinelist, pipelineId]);

  const [active, setActive] = useState(true);

  // on form submit we check some fields and use this state, to either show a error message or not
  const [valid, setValid] = useState(true);

  // some css classes
  const classes = useStyles();

  // when the prop filter changes, we update our values, for example on a reset
  useEffect(() => {
    setFieldId(filter.field_id);
    setFilterModeId(filter.filter_mode_id);
    setValue(filter.value);
    setValue2(filter.value2 || '');
    setUserIds(filter.user_ids?.map((ui) => ui.userid) || []);
    setPipelineId(filter.pipeline_id);
    setActive(filter.active || false);
  }, [filter]);

  /**
   * we update our prop filter value for saving purposes
   */
  const buildFilter = (): Filter => {
    const f: Filter = filter;
    f.field_id = fieldId;
    f.filter_mode_id = filterModeId;
    f.user_ids = userIds.map((u) => ({ userid: u, state: State.INACTIVE }));
    f.value = value;
    f.value2 = value2;
    f.pipeline_id = pipelineId;
    f.active = active;
    return f;
  };

  /**
   * We call the given function with the event.target.value as first param.
   * @param stateFunc function to call onChange, should be function returned from 'useState'
   */
  const handleChange = (stateFunc: Dispatch<SetStateAction<any>>) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      stateFunc(event.target.value);
    };
  };

  /**
   * we set the componentn to non-editable and aske the parent what to do on a cancel
   */
  const handleCancel = () => {
    setEditable(false);
    onCancel();
  };

  /**
   * We take care of submiting the form ourselfs. We validate the inputs. If everythings works, we call the onSave method from our parent.
   * @param event react form event
   */
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isValid: boolean = true;
    if (editable) {
      const validFieldId: boolean = Field.getFieldById(fieldId) != null;
      isValid = isValid && validFieldId;

      const validFilterMode: boolean =
        FilterMode.getModeById(filterModeId) != null;
      isValid = isValid && validFilterMode;

      const validValue = !!value && value !== '';
      isValid = isValid && validValue;

      if (isValid && onSave && filterMode() && field()) onSave(buildFilter());
    }

    if (!editable || isValid) setEditable(!editable);
    setValid(isValid);
  };

  /**
   * Helper function, to create a MenuItem
   * @param b
   */
  const basicToMenuItem = (b: BasicType) => {
    return (
      <MenuItem key={b.id} value={b.id}>
        {b.label}
      </MenuItem>
    );
  };

  /**
   * we build the Field select options and use the react memo to save some performance
   */
  const fieldOpts = useMemo(() => {
    return Field.VALUES.map(basicToMenuItem);
  }, []);

  /**
   * we build the Filtermode select options and use the react memo to save some performance
   */
  const modeOpts = useMemo(() => {
    return FilterMode.VALUES.map(basicToMenuItem);
  }, []);

  /**
   * we build the user select options and use the react memo to save some performance
   */
  const userOpts = useMemo(() => {
    return userlist.map((u) => {
      return (
        <MenuItem key={u.pipedrive_id} value={u.pipedrive_id}>
          {u.name}
          {userIds.includes(u.pipedrive_id) ? <Check /> : ''}
        </MenuItem>
      );
    });
  }, [userlist, userIds]);

  /**
   * we build the pipeline select options and use the react memo to save some performance
   */
  const pipelineOpts = useMemo(() => {
    return pipelinelist.map((p) => {
      return (
        <MenuItem
          key={p.pipedrive_id}
          disabled={!p.active}
          value={p.pipedrive_id}
        >
          {p.name}
        </MenuItem>
      );
    });
  }, [pipelinelist]);

  /**
   * Helper function, to decide what our value inputs should be like. Handles the special cases
   *  - FilterMode.BETWEEN
   *  - Field.PLZ
   */
  const makeFilterValueFields = () => {
    switch (filterModeId) {
      case FilterMode.BETWEEN.id:
        return (
          <Fragment>
            <FormControl required>
              <TextField
                required
                value={value}
                onChange={handleChange(setValue)}
                label="Wert"
                type="number"
              />
              <FormHelperText>Inclusive</FormHelperText>
            </FormControl>
            <span> & </span>
            <FormControl required>
              <TextField
                required
                value={value2}
                onChange={handleChange(setValue2)}
                label="Wert"
                type="number"
              />
              <FormHelperText>Inclusive</FormHelperText>
            </FormControl>
          </Fragment>
        );
      default:
        return (
          <FormControl required>
            <TextField
              required
              value={value}
              onChange={handleChange(setValue)}
              label="Wert"
              type={
                fieldId === Field.PLZ.id || fieldId === Field.AGE.id
                  ? 'number'
                  : 'text'
              }
            />
          </FormControl>
        );
    }
  };

  // our main content
  let main;
  if (!editable) {
    // we only show, what is localy saved for this filter
    main = (
      <div className={`${classes.root} ${classes.mr}`}>
        <Typography variant="body1">
          {field()?.label} {filterMode()?.label} &apos;{value}&apos;{' '}
          {filterModeId === FilterMode.BETWEEN.id ? (
            <Fragment>
              <span> & </span> &apos;{value2}&apos;
            </Fragment>
          ) : (
            ''
          )}{' '}
          {pipeline()?.name}{' '}
          {userlist
            .filter((u) => userIds.includes(u.pipedrive_id))
            .map((u) => u.name)
            .join(', ')}
        </Typography>
        {active ? (
          <CheckBoxOutlined className={classes['active-icon']} />
        ) : (
          <CheckBoxOutlineBlank className={classes['active-icon']} />
        )}
      </div>
    );
  } else {
    // we show all the inputs, for editing the filter
    main = (
      <div className={classes['inputs-container']}>
        <div className={classes.inputs}>
          <FormControl required>
            <InputLabel id="field-label">Feld</InputLabel>
            <Select
              required
              labelId="field-label"
              value={fieldId}
              onChange={handleChange(setFieldId)}
            >
              <MenuItem disabled value="">
                Feld auswählen
              </MenuItem>
              {fieldOpts}
            </Select>
          </FormControl>

          <FormControl required>
            <InputLabel id="filter-mode-label">Modus</InputLabel>
            <Select
              required
              labelId="filter-mode-label"
              value={filterModeId}
              onChange={handleChange(setFilterModeId)}
            >
              {modeOpts}
            </Select>
          </FormControl>

          {makeFilterValueFields()}

          <FormControl required>
            <InputLabel id="pipeline-label">Pipeline</InputLabel>

            <Select
              labelId="pipeline-label"
              value={pipelineId}
              onChange={handleChange(setPipelineId)}
            >
              {pipelineOpts}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel id="user-chip-label">Chip</InputLabel>
            <Select
              labelId="user-chip-label"
              multiple
              value={userIds}
              onChange={handleChange(setUserIds)}
              MenuProps={MenuProps}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as number[]).map((v) => (
                    <Chip
                      key={v}
                      label={userlist.find((u) => u.pipedrive_id === v)?.name}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {userOpts}
            </Select>
          </FormControl>
          <FormControl>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  color="primary"
                  checked={active}
                  onChange={(e, c) => setActive(c)}
                />
              }
              label="Aktiv"
              labelPlacement="start"
            />
            {/* <Checkbox
              value={filter.active}
              checked={filter.active}
              onChange={(e, c) => (filter.active = c)}
            ></Checkbox> */}
          </FormControl>
        </div>
        {!valid ? (
          <Typography color="error">Fehler bei der Eingabe</Typography>
        ) : (
          ''
        )}
      </div>
    );
  }

  // we use all the small parts to build our component. Its basicly a form with intputs.
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      {main}
      {editable && (
        <IconButton size="small" onClick={() => handleCancel()}>
          <Cancel />
        </IconButton>
      )}
      {!deleteMode && (
        <IconButton size="small" type="submit">
          {editable ? <Check /> : <Edit />}
        </IconButton>
      )}
    </form>
  );
}

/**
 * Our redux root - react props mapping.
 * @param state Redux root state
 */
const mapStateToProps = (state: RootState) => ({
  userlist: selectors.getActiveUserlist(state),
  pipelinelist: selectors.getPipelineList(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(FilterElement);
