import React, { useCallback, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Days, DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import {
  ArrowForward,
  Delete,
  EventNote,
  LocationOn,
} from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import * as DateUtil from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  withStyles,
  WithStyles,
} from '@material-ui/core';

import { RootState } from '../../features/store';
import { ApiActions } from '../../features/aws/dynamodb/actions';
import { TimeframeExtended } from '../../util/interfaces';
import { DialogTitle } from '../util/UtilComponents';
import UserSelect from '../util/UserSelect';
import { getTranslation } from '../../i18n/timeframetype';
import TimeframePipelineTable from './TimeframePipelineTable';
import { getDateFromNumber } from '../../util';
import ConfirmationDialog from '../util/ConfirmationDialog';
import { selectors } from '../../features/pipedrive';

type FormValues = {
  type: string;
  userid: number;
  title: string;
  start_date: Date;
  end_date: Date | null;
  [key: string]: boolean | string | Date | number | null | any;
};

interface Props extends ReduxProps, Styles {
  frame: TimeframeExtended;
  onClose: () => void;
  onSave: (frame: TimeframeExtended) => void;
}

const TimeframeDialogForm = ({
  classes,
  deleteTimeframe,
  frame,
  onClose,
  onSave,
  timeframetypes,
  timeframes,
  userlist,
}: Props) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const formMethods = useForm<FormValues>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    defaultValues: {
      userid: frame.userid,
      type: frame.type?.key || '-',
      title: frame.title || '',
      start_date: new Date(frame.start_date) || null,
      end_date: frame.end_date ? new Date(frame.end_date) : null,
      ...Object.keys(frame.days).reduce((v, pipeID) => {
        const pipelineID = parseInt(pipeID, 10);
        return {
          ...v,
          ...Days.reduce(
            (vd, d) => ({
              ...vd,
              [pipelineID + '_' + d]: frame.days[pipelineID][d] || false,
            }),
            {}
          ),
        };
      }, {}),
      ...Days.reduce(
        (v, d) => ({
          ...v,
          [`useTime_${d}`]:
            (frame.time_settings && frame.time_settings[d]?.time_active) ||
            false,
          [`start_${d}`]:
            getDateFromNumber(
              frame.time_settings && frame.time_settings[d]?.start_time
            ) || null,
          [`end_${d}`]:
            getDateFromNumber(
              frame.time_settings && frame.time_settings[d]?.end_time
            ) || null,
        }),
        {}
      ),
    },
  });
  const watchValues = formMethods.watch([
    'type',
    'title',
    'userid',
    'start_date',
  ]);

  const getTitle = useCallback((): string => {
    return watchValues.title && watchValues.title !== ''
      ? watchValues.title
      : getTranslation(
          timeframetypes.find((t) => t.key === watchValues.type),
          de
        );
  }, [timeframetypes, watchValues.title, watchValues.type]);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => setIsConfirmDeleteOpen(true);

  const handleConfirmedDelete = () => {
    deleteTimeframe([frame.id]);
    handleClose();
  };

  const handleSave = (values: FormValues) => {
    // console.log(values, frame);
    const pipelinesReg = new RegExp('^(\\d+)_(' + Days.join('|') + ')', 'im');
    const timeStartTimeReg = new RegExp(`^start_(${Days.join('|')})`, 'im');
    const timeEndTimeReg = new RegExp(`^end_(${Days.join('|')})`, 'im');
    const useTimeReg = new RegExp(`^useTime_(${Days.join('|')})`, 'im');

    const data: DynamoDBObjects.Timeframe = {
      pipedrive_company_id: frame.pipedrive_company_id,
      id: frame.id,
      days: {},
      title: values.title,
      type: timeframetypes.find((tt) => tt.key === values.type),
      userid: values.userid,
      start_date: DateUtil.startOfDay(values.start_date).getTime(),
      end_date: values.end_date
        ? DateUtil.endOfDay(values.end_date).getTime()
        : undefined,
    };

    Object.entries(values).forEach(([key, value]) => {
      // pipelinesReg.lastIndex = 0;
      if (pipelinesReg.test(key)) {
        const matches = pipelinesReg.exec(key);
        if (!matches) return;
        const pID = parseInt(matches[1], 10);
        if (!data.days[pID]) data.days[pID] = {};
        data.days[pID][matches[2]] =
          typeof value === 'boolean' ? value : undefined;
      } else if (useTimeReg.test(key)) {
        const matches = useTimeReg.exec(key);
        if (!matches) return;
        if (!data.time_settings) data.time_settings = {};
        if (!(matches[1] in data.time_settings))
          data.time_settings[matches[1]] = {
            time_active: false,
          };
        data.time_settings[matches[1]].time_active =
          typeof value === 'boolean' ? value : false;
      } else if (timeStartTimeReg.test(key)) {
        const matches = timeStartTimeReg.exec(key);
        if (!matches) return;
        if (!data.time_settings) data.time_settings = {};
        if (!(matches[1] in data.time_settings))
          data.time_settings[matches[1]] = {
            time_active: false,
          };
        data.time_settings[matches[1]].start_time = value
          ? DateUtil.getHours(value as Date) * 100 +
            DateUtil.getMinutes(value as Date)
          : undefined;
      } else if (timeEndTimeReg.test(key)) {
        const matches = timeEndTimeReg.exec(key);
        if (!matches) return;
        if (!data.time_settings) data.time_settings = {};
        if (!(matches[1] in data.time_settings))
          data.time_settings[matches[1]] = {
            time_active: false,
          };
        data.time_settings[matches[1]].end_time = value
          ? DateUtil.getHours(value as Date) * 100 +
            DateUtil.getMinutes(value as Date)
          : undefined;
      }
    });

    console.log(data);
    onSave(data);
    handleClose();
  };

  /**
   * wir checken, an welchen Daten wir keinen Timeframe anfangen lassen können
   * @param date Datum im Popup Kalender
   * @return true wenn das übergebene Datum disabled werden soll, sonst false
   */
  const disableDate = useCallback<(date: Date | null) => boolean>(
    (date: Date | null): boolean => {
      if (date) {
        // wenn wir suchen nach einem Timeframe, der das übergebene Datum enthält
        const f = (timeframes || [])
          .filter((t) => t.userid === watchValues.userid)
          .find((t) => {
            if (t.id === frame.id) return false;
            if (!t.end_date) return DateUtil.isBefore(t.start_date, date);
            return DateUtil.isWithinInterval(date, {
              start: t.start_date,
              end: t.end_date,
            });
          });
        // wenn wir einen Timeframe finden, wollen wir das Datum disablen
        return !!f;
      }
      return false;
    },
    [frame.id, timeframes, watchValues.userid]
  );

  /**
   * wir checken, ab welchem Datum wir den Timeframe nicht mehr enden lassen können.
   */
  const maxDate = useMemo(() => {
    const resultFrame: DynamoDBObjects.Timeframe | undefined = (
      timeframes || []
    )
      .filter((t) => t.userid === watchValues.userid)
      .filter((t) => t.id !== frame.id)
      .find((tf) => {
        if (
          tf.end_date &&
          watchValues.start_date &&
          DateUtil.isBefore(tf.end_date, watchValues.start_date)
        )
          return false;
        return (
          watchValues.start_date &&
          DateUtil.isAfter(tf.start_date, watchValues.start_date)
        );
      });

    return resultFrame
      ? DateUtil.subDays(resultFrame.start_date, 1)
      : undefined;
  }, [watchValues.start_date, watchValues.userid, timeframes, frame.id]);

  return (
    <>
      <Dialog open={true} maxWidth={false}>
        <DialogTitle onClose={handleClose}>
          Timeframe{frame.title ? ` - ${frame.title}` : ''}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormProvider {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit(handleSave)}
              id="data-form"
            >
              <div className={classes.upper}>
                <UserSelect />
                <div className={`${classes['form-control']} dates`}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                    <EventNote className="ui-icon" />
                    <Controller
                      name={`start_date`}
                      control={formMethods.control}
                      render={(props) => (
                        <DatePicker
                          variant="dialog"
                          label={null}
                          showTodayButton
                          todayLabel="Heute"
                          cancelLabel="Abbrechen"
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          shouldDisableDate={disableDate}
                          {...props}
                        />
                      )}
                    />
                    <ArrowForward className="ui-icon date-to" />
                    <Controller
                      name={`end_date`}
                      control={formMethods.control}
                      render={(props) => (
                        <DatePicker
                          variant="dialog"
                          clearable
                          clearLabel="Löschen"
                          cancelLabel="Abbrechen"
                          showTodayButton
                          todayLabel="Heute"
                          label={null}
                          format="dd.MM.yyyy"
                          inputVariant="outlined"
                          minDate={watchValues.start_date}
                          maxDate={maxDate}
                          shouldDisableDate={disableDate}
                          {...props}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="types">
                  <LocationOn className="ui-icon" />
                  <Controller
                    name="type"
                    as={
                      <Select>
                        {timeframetypes.map((t) => (
                          <MenuItem key={t.key} value={t.key}>
                            {t.background && (
                              <div
                                className={classes['type-option-info']}
                                style={{
                                  backgroundColor: t.background,
                                }}
                              />
                            )}
                            {getTranslation(t, de)}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </div>
              </div>
              <div
                className={[
                  classes['pipeline-table-container'],
                  classes.overflowx,
                ].join(' ')}
              >
                <TimeframePipelineTable classes={classes} frame={frame} />
              </div>
              <div className={classes['pipeline-table-container']}>
                <TextField
                  inputRef={formMethods.register({
                    validate: (value) => {
                      if (value != null && value.length > 0) return true;
                      return !!(watchValues.type && watchValues.type !== '-');
                    },
                  })}
                  style={{ width: '100%' }}
                  name="title"
                  placeholder={
                    (watchValues.type &&
                      getTranslation(
                        timeframetypes.find((t) => t.key === watchValues.type),
                        de
                      )) ||
                    'Info'
                  }
                  size="medium"
                  variant="outlined"
                  error={!!formMethods.errors.title}
                />
              </div>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className={classes['dialog-actions']}>
          {frame && frame.id > -1 && (
            <Button
              onClick={handleDelete}
              className="deleteButton"
              variant="contained"
              color="secondary"
              startIcon={<Delete />}
            >
              Löschen
            </Button>
          )}
          <div />
          <Button
            onClick={() => {
              console.log('abbrechen timeframedialog');
              handleClose();
            }}
            variant="contained"
          >
            Abbrechen
          </Button>
          <Button form="data-form" type="submit" variant="contained">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      {isConfirmDeleteOpen && (
        <ConfirmationDialog
          title={`Löschen von '${getTitle()}'`}
          onConfirm={handleConfirmedDelete}
          onClose={() => setIsConfirmDeleteOpen(false)}
        >
          <>
            <p>
              Möchten Sie den Timeframe: <i>{getTitle()}</i>
            </p>
            <p>
              von: {userlist.find((u) => u.pipedrive_id === frame.userid)?.name}
            </p>
            <p>wirklich löschen?</p>
          </>
        </ConfirmationDialog>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pipelines: state.consult.pipelines,
  userlist: selectors.getActiveUserlist(state),
  timeframetypes: state.consult.timeframetypes,
  timeframes: state.consult.timeframes,
});

const mapDispatchToProps = {
  deleteTimeframe: ApiActions.deleteTimeframe,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const styles = () =>
  createStyles({
    content: {
      padding: '0',
    },
    upper: {
      background: '#F8F8F8',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '10px 0',
      '& .dates .MuiPickersDateRangePickerInput-rangeInputsContainer': {
        alignItems: 'center',
      },
      '& .dates, .types': {
        display: 'flex',
        alignItems: 'center',
        margin: '0 20px',
        justifyContent: 'space-around',
        '& svg.ui-icon': {
          fontSize: '2rem',
          color: '#78909C',
          margin: '0 8px',
        },
      },
    },
    'form-control': {
      '&.right-margin': {
        marginRight: '20px',
      },
      '& .MuiInputBase-root': {
        backgroundColor: '#fff',
        '& input, .MuiSelect-root.MuiOutlinedInput-input': {
          padding: '12px 32px 12px 10px',
        },
        '& .MuiInputAdornment-positionEnd': {
          marginLeft: 0,
        },
      },
      '& .MuiInputAdornment-root button:hover': {
        backgroundColor: 'transparent',
      },
    },
    'dialog-actions': {
      background: '#ECEFF1',
      padding: '16px',
      '& button:not(:first-child)': {
        marginLeft: '40px',
      },
      '& button': {
        background: '#fff',
      },
      '& button.deleteButton': {
        background: '#78909C',
      },
      '& button.deleteButton:hover': {
        filter: 'brightness(1.1)',
      },
      '& div': {
        flex: 1,
      },
    },
    'pipeline-table-container': {
      padding: '20px',
    },
    overflowx: {
      overflowX: 'auto',
    },
    'pipeline-table': {
      border: '1px solid #E1E0EA',
      '& th.MuiTableCell-head:first-child': {
        fontWeight: 'normal',
      },
      '& th, td': {
        border: '1px solid #E1E0EA',
        minWidth: '120px',
        height: '20px',
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
          background: '#F2F2F2',
          width: '200px',
          fontSize: '16px',
          fontWeight: '300',
        },
        '&:nth-child(2)': {
          background: '#F2F2F2',
          minWidth: '30px',
        },
      },
      '& .time-row .time': {
        display: 'flex',
        alignItems: 'center',
        '& .label': {
          marginRight: '4px',
        },
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
    },
    'type-option-info': {
      display: 'inline-flex',
      height: '10px',
      width: '10px',
      border: '1px solid gray',
      marginRight: '8px',
    },
  });
type Styles = WithStyles<typeof styles>;

export default connector(
  withStyles(styles, { withTheme: true })(TimeframeDialogForm)
);
