import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import {
  LoadSpecificTypeResponse,
  LoadSpecificTypeResponseS,
} from '../../pipedrive/types';
import {
  updateErrorMessageAction,
  updateSucessMessageDispatch,
} from '../../util/message-action';
import AWSAPI, { DYNAMODB_TYPE } from './api';
import { ApiError } from '../../../util/apiError';
import { TimeframeExtended } from '../../../util/interfaces';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

export type MiddlewareAPIResponse =
  | DynamoDBObjects.Pipeline[]
  | DynamoDBObjects.User[]
  | DynamoDBObjects.Timeframe[]
  | DynamoDBObjects.Filter[]
  | number[]
  | LoadSpecificTypeResponse<DynamoDBObjects.Timeframe>
  | LoadSpecificTypeResponse<DynamoDBObjects.Filter>
  | LoadSpecificTypeResponseS<DynamoDBObjects.User>
  | void;

function callApi(
  type: DYNAMODB_TYPE,
  payload?: any
): Promise<MiddlewareAPIResponse> {
  switch (type) {
    case DYNAMODB_TYPE.PIPELINES:
      return AWSAPI.getPipelines();
    case DYNAMODB_TYPE.SAVE_PIPELINE:
      return AWSAPI.savePipelines(payload as DynamoDBObjects.Pipeline[]);
    case DYNAMODB_TYPE.LOAD_USER:
      return AWSAPI.getUserlist(payload as string[]);
    case DYNAMODB_TYPE.SAVE_USER:
      return AWSAPI.saveUserlist(payload as DynamoDBObjects.User[]);
    case DYNAMODB_TYPE.SAVE_FILTERS:
      return AWSAPI.saveFilterList(payload as DynamoDBObjects.Filter[]);
    case DYNAMODB_TYPE.LOAD_FILTERS:
      return AWSAPI.getFilters(payload as number[]);
    case DYNAMODB_TYPE.DELETE_FILTERS:
      return AWSAPI.deleteFilter(payload as number[]);
    case DYNAMODB_TYPE.LOAD_TIMEFRAMES:
      return AWSAPI.getTimeframes(payload as number[]);
    case DYNAMODB_TYPE.SAVE_TIMEFRAMES:
      return AWSAPI.saveTimeframes(payload as TimeframeExtended[]);
    case DYNAMODB_TYPE.DELETE_TIMEFRAMES:
      return AWSAPI.deleteTimeframes(payload as number[]);
    default:
      return Promise.reject(
        new ApiError(`Unknown dynamodb_type: ${type} to load from`)
      );
  }
}

export const DynamoDBApi = (store: MiddlewareAPI) => (next: Dispatch) => (
  action: AnyAction
) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { type, reqTypes, payload } = callAPI;

  const actionWith = (data: AnyAction) => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = reqTypes;
  next(actionWith({ type: requestType }));

  return callApi(type, payload).then(
    (response: MiddlewareAPIResponse) => {
      const { successInfo } = callAPI;
      updateSucessMessageDispatch(successType, successInfo)(store.dispatch);

      return next(
        actionWith({
          response,
          type: successType,
        })
      );
    },
    (error: ApiError) => {
      console.log(`error calling api:`, error);

      next(
        actionWith(
          updateErrorMessageAction(
            error.message || 'Something bad happened',
            failureType
          )
        )
      );
    }
  );
};
