import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import {
  ConsultActionTypes,
  ConsultState,
  LoadSpecificTypeResponse,
  LoadSpecificTypeResponseS,
  RESET_CONSULT,
} from './types';
import {
  DELETE_FILTER_SUCCESS,
  DELETE_TIMEFRAME_SUCCESS,
  LOAD_FILTER_SUCCESS,
  LOAD_SPECIFIC_FILTER_SUCCESS,
  LOAD_SPECIFIC_USER_SUCCESS,
  LOAD_TIMEFRAME_INIT_SUCCESS,
  LOAD_TIMEFRAME_SUCCESS,
  SAVE_FILTER_SUCCESS,
  UPDATE_FILTER,
  UPDATE_INIT_DATA,
  UPDATE_PIPELINE,
  UPDATE_TIMEFRAME,
  UPDATE_TYPE,
  UPDATE_USER,
} from '../aws/dynamodb/actions';

const initialState: ConsultState = {
  users: [],
  pipelines: [],
  filters: [],
  timeframes: undefined,
  timeframetypes: [],
};

export default (
  state = initialState,
  action: ConsultActionTypes
): ConsultState => {
  switch (action.type) {
    case RESET_CONSULT:
      return initialState;

    case UPDATE_INIT_DATA:
      const [
        users,
        pipelines,
        filters,
        timeframes,
        timeframetypes,
      ] = action.payload;
      return {
        ...state,
        users,
        pipelines,
        filters,
        timeframes,
        timeframetypes,
      };
    case LOAD_TIMEFRAME_SUCCESS:
    case LOAD_TIMEFRAME_INIT_SUCCESS:
      if (action.response)
        return {
          ...state,
          timeframes: action.response as DynamoDBObjects.Timeframe[],
        };
      return state;
    case UPDATE_TIMEFRAME: {
      const { value } = action.payload as {
        value: DynamoDBObjects.Timeframe;
      };

      const newTimeframes = [...(state.timeframes || [])];
      const index = newTimeframes.findIndex((t) => {
        return t.id === value.id;
      });
      if (index >= 0) {
        newTimeframes[index] = value;
      } else {
        newTimeframes.push(value);
      }
      return { ...state, timeframes: newTimeframes };
    }

    case DELETE_TIMEFRAME_SUCCESS: {
      const ids = action.response as number[];
      if (!state.timeframes) return state;

      const newTimeframes = state.timeframes.filter((f) => !ids.includes(f.id));

      return {
        ...state,
        timeframes: newTimeframes,
      };
    }

    case SAVE_FILTER_SUCCESS:
      console.log('save filter', action, state);
      const newFilter = [...state.filters];
      newFilter.forEach((f) => {
        if ('modified' in f) {
          delete f.modified;
        }
      });
      return { ...state, filters: newFilter };

    case LOAD_FILTER_SUCCESS:
      return { ...state, filters: action.response as DynamoDBObjects.Filter[] };

    case LOAD_SPECIFIC_FILTER_SUCCESS: {
      const oldFilter = [...state.filters];
      const { ids, value } = action.response as LoadSpecificTypeResponse<
        DynamoDBObjects.Filter
      >;

      const newFilter = [];
      for (const f of oldFilter) {
        if (ids.includes(f.sort_key)) {
          if (f.sort_key in value && !!value[f.sort_key]) {
            // eslint-disable-next-line prefer-object-spread
            newFilter.push(Object.assign({}, f, value[f.sort_key]));
          }
        } else {
          newFilter.push(f);
        }
      }

      return { ...state, filters: newFilter };
    }

    case UPDATE_FILTER: {
      const { value, index } = action.payload as UPDATE_TYPE<
        DynamoDBObjects.Filter
      >;

      const newFilter = [...state.filters];
      if (index >= 0) {
        newFilter[index] = value;
      } else {
        newFilter.push(value);
      }
      return { ...state, filters: newFilter };
    }

    case DELETE_FILTER_SUCCESS: {
      const ids = action.response as number[];
      const newFilter = state.filters.filter((f) => !ids.includes(f.sort_key));

      return { ...state, filters: newFilter };
    }

    case LOAD_SPECIFIC_USER_SUCCESS: {
      const oldUser = [...state.users];
      const { ids, value } = action.response as LoadSpecificTypeResponseS<
        DynamoDBObjects.User
      >;

      const newUsers: Array<DynamoDBObjects.User> = [];
      for (const u of oldUser) {
        if (ids.includes(u.pk) && value[u.pk] != null) {
          const v = value[u.pk];
          if (v) newUsers.push({ ...v });
        } else {
          newUsers.push(u);
        }
      }
      return { ...state, users: newUsers };
    }

    case UPDATE_USER: {
      const { value } = action.payload as UPDATE_TYPE<DynamoDBObjects.User>;

      const newUsers = [...state.users];
      const i = newUsers.findIndex((u) => u.pk === value.pk);
      if (i >= 0) {
        newUsers[i] = value;
      } else {
        newUsers.push(value);
      }
      return { ...state, users: newUsers };
    }

    case UPDATE_PIPELINE: {
      const { value, index } = action.payload as UPDATE_TYPE<
        DynamoDBObjects.Pipeline
      >;

      const newPipelines = [...state.pipelines];
      if (index >= 0) {
        newPipelines[index] = value;
      } else {
        newPipelines.push(value);
      }
      return { ...state, pipelines: newPipelines };
    }
    default:
      return state;
  }
};

export { initialState as ConsultInitState };
