import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { ConsultOverviewPage } from './pages/consult-overview/Consult-Overview';
import { Home } from './pages/Home';
import { Actions, AppAbility, Subjects } from './casl';
import { AbilityContext } from './casl/CaslCan';
import { RouteProps } from 'react-router';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';

const Routes: { [key: string]: RouteProps & { subject?: Subjects } } = {
  home: {
    path: '/',
    component: Home,
    exact: true,
  },
  consultOverview: {
    path: '/consult-overview',
    component: ConsultOverviewPage,
    exact: true,
    subject: Subjects.CONSULT_PREFERENCES,
  },
  any: {
    path: '*',
    render: () => (
      <>
        <h3>Unbekannte Route</h3>
        <NavLink to="/">Home</NavLink>
      </>
    ),
    exact: true,
  },
};

function getRouteElement({
  subject,
  ...r
}: RouteProps & { subject?: Subjects }) {
  return <Route {...r} key={r.path + ''} />;
}

const initialState: JSX.Element[] = [];

function reducer(
  state: typeof initialState,
  action: { type: string; ability: AppAbility }
) {
  switch (action.type) {
    case 'update':
      return Object.values(Routes)
        .filter((r) => {
          if ('subject' in r && r.subject != null) {
            return action.ability.can(Actions.SEE, r.subject);
          }

          return true;
        })
        .map(getRouteElement);

    case AuthState.SignedOut:
      return [getRouteElement(Routes.home)];
    default:
      return state;
  }
}

const App = () => {
  const ability = useContext(AbilityContext);
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();
  const [routes, dispatch] = useReducer(reducer, [], () => {
    return Object.values(Routes)
      .filter((r) => {
        if ('subject' in r && r.subject != null) {
          return ability.can(Actions.SEE, r.subject);
        }

        return true;
      })
      .map(getRouteElement);
  });

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      dispatch({ type: nextAuthState, ability });
      setUser(authData);
    });
  }, [ability]);

  useEffect(() => {
    return ability.on('updated', (a) => {
      dispatch({ type: 'update', ability: a.ability });
    });
  }, [ability]);

  return (
    <Fragment>
      <div className="container">
        {authState === AuthState.SignedIn && user ? (
          <Switch>{routes}</Switch>
        ) : (
          <AmplifyAuthenticator initialAuthState={AuthState.SignIn}>
            <AmplifySignIn hideSignUp slot="sign-in" />
          </AmplifyAuthenticator>
        )}
      </div>
    </Fragment>
  );
};

export default App;
