// eslint-disable react/destructuring-assignment
import {
  Button,
  Checkbox,
  createStyles,
  Fab,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  WithStyles,
  Paper,
} from '@material-ui/core';
import { Add, Menu } from '@material-ui/icons';
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApiActions, ReduxActions } from '../../features/aws/dynamodb/actions';
import { selectors } from '../../features/pipedrive';
import { RootState } from '../../features/store';
import { FilterExt, FilterMode } from '../../util/interfaces';
import Savable from '../../util/interfaces/Savable';
import FilterElement from './filter-element/FilterElement';
import FilterCreateDialog from './FilterCreateDialog';

type Filter = DynamoDBObjects.Filter;

interface Props extends ReduxProps, Styles {}

interface State {
  dataChanged: boolean;
  // filter: Array<FilterExt>;
  createOpen: boolean;
  deleteIds: number[];
}

const styles = createStyles({
  hint: {
    fontSize: '0.9em',
    opacity: '0.7',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  avatarCol: {
    width: '50px',
    backgroundColor: '#F6F5FC',
    border: 'none',
    borderBottom: '1px solid #F6F5FC',
  },
  avatar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
  },
  namecol: {
    maxWidth: '210px',
    width: '210px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    backgroundColor: '#F6F5FC',
    border: 'none',
    borderRight: '10px solid #fff',
    borderBottom: '1px solid #F6F5FC',
  },

  'small-header': {
    width: '20%',
  },
  'fab-add-row': {
    position: 'absolute',
    right: '16px',
    marginTop: '10px',
  },
  'button-area': {
    padding: '4px',
    margin: '4px',
    '& button': {
      marginLeft: '10px',
    },
  },
  'button-area-delete': {
    textAlign: 'right',
  },
  row: {
    // '&.selected': {
    //   background: 'rgba(59, 145, 3, 0.1)',
    // },
  },
});

class FilterSettings extends Component<Props, State> implements Savable {
  constructor(props: Props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.addFilterRow = this.addFilterRow.bind(this);
    this.handleSaveFilter = this.handleSaveFilter.bind(this);
    this.handleDeleteCheckChange = this.handleDeleteCheckChange.bind(this);
    this.handleCreateClose = this.handleCreateClose.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);

    this.state = {
      dataChanged: false,
      createOpen: false,
      deleteIds: [],
    };
  }

  handleSave() {
    // we filter and map everything into the format we want, for  saving only the filter that where modified
    const filterToSave = this.props.filter
      .filter(f => f.modified)
      .map(f => {
        const newf: Filter = {
          pipedrive_company_id: f.pipedrive_company_id,
          sort_key: f.sort_key,
          name: f.name,
          filter_mode_id: f.filter_mode_id,
          field_id: f.field_id,
          value: f.value,
          user_ids: f.user_ids,
          pipeline_id: f.pipeline_id,
          active: f.active,
        };
        if (f.value2 && f.value2 !== '') {
          newf.value2 = f.value2;
        }
        return newf;
      });

    this.props.saveFilter(filterToSave);
    this.setState({ dataChanged: false });
  }

  handleCancel(): void {
    this.props.loadFilter(
      this.props.filter.filter(f => f.modified).map(f => f.sort_key)
    );
    this.setState({ dataChanged: false });
  }

  handleSaveFilter(f: FilterExt, index: number) {
    console.log(`saving filter ${f.name} (${f.sort_key})`);
    this.setState({ dataChanged: true });
    this.props.updateFilter({ ...f, modified: true }, index);
  }

  handleCancelFilter(index: number) {
    this.props.updateFilter(this.props.filter[index], index);
  }

  addFilterRow(): void {
    this.setState({ createOpen: true });
  }

  handleCreateClose(value: string | undefined): void {
    this.setState({ createOpen: false });
    if (value == null || value === '' || !this.props.company_id) return;
    const f: FilterExt = {
      pipedrive_company_id: this.props.company_id,
      name: value,
      sort_key: this.findNextSortKey(),
      field_id: '',
      filter_mode_id: FilterMode.EQUAL.id,
      filterMode: FilterMode.EQUAL,
      edit: true,
      modified: true,
      value: '',
      user_ids: [],
      pipeline_id: 0,
      active: true,
    };

    this.props.updateFilter(f, -1);
    this.setState({ dataChanged: true });
  }

  private handleDeleteCheckChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const sortkey = parseInt(event.target.value, 10);
    this.setState(prevState => {
      if (sortkey === -1) {
        if (checked) {
          return { deleteIds: this.props.filter.map(f => f.sort_key) };
        }
        return { deleteIds: [] };
      }
      if (checked) {
        return { deleteIds: [...prevState.deleteIds, sortkey] };
      }
      return {
        deleteIds: prevState.deleteIds.splice(
          prevState.deleteIds.findIndex(i => i === sortkey),
          1
        ),
      };
    });
  }

  private handleDeleteSelected(): void {
    this.props.deleteFilter(this.state.deleteIds);
    this.setState({ deleteIds: [] });
  }

  private handleDeleteCancel(): void {
    this.setState({ deleteIds: [] });
  }

  private findNextSortKey(): number {
    let key: number = -1;
    for (const f of this.props.filter) {
      if (f.sort_key > key) key = f.sort_key;
    }
    key += 1;
    return key;
  }

  render() {
    const { dataChanged, deleteIds } = this.state;
    const { filter: filterlist } = this.props;
    const classes = this.props.classes as { [P in keyof typeof styles]: P };

    const createFilterRow = (filter: Filter, index: number) => {
      const isItemSelected = this.state.deleteIds.includes(filter.sort_key);
      return (
        <TableRow
          key={filter.sort_key}
          selected={isItemSelected}
          classes={{ selected: 'selected' }}
          className={classes.row}
        >
          <TableCell className={classes.avatarCol}>{index + 1}</TableCell>
          <TableCell className={classes.namecol}>{filter.name}</TableCell>
          <TableCell>
            <FilterElement
              onSave={f => this.handleSaveFilter(f, index)}
              onCancel={() => this.handleCancelFilter(index)}
              deleteMode={deleteIds.length > 0}
              filter={{ ...filter }}
            />
          </TableCell>
          <TableCell align="right" style={{ width: '32px' }}>
            <IconButton
              size="small"
              disabled
              //   onClick={e => this.}
            >
              <Menu />
            </IconButton>
          </TableCell>
          <TableCell align="right" style={{ width: '32px' }}>
            {!dataChanged && (
              <FormControl>
                <Checkbox
                  value={filter.sort_key}
                  checked={isItemSelected}
                  onChange={this.handleDeleteCheckChange}
                />
              </FormControl>
            )}
          </TableCell>
        </TableRow>
      );
    };

    const filterRows = filterlist.map(createFilterRow);

    return (
      <Paper style={{ paddingRight: '10px' }}>
        <Typography variant="h6" gutterBottom>
          Filter
        </Typography>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.avatarCol}>#</TableCell>
                <TableCell className={classes.namecol} size="small">
                  Name
                </TableCell>
                <TableCell size="medium">Filter</TableCell>
                <TableCell />
                <TableCell align="right" style={{ width: '32px' }}>
                  {!dataChanged && this.props.filter.length > 0 && (
                    <FormControl>
                      <Checkbox
                        value="-1"
                        checked={deleteIds.length === this.props.filter.length}
                        onChange={this.handleDeleteCheckChange}
                      />
                    </FormControl>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{filterRows}</TableBody>
          </Table>
        </TableContainer>
        {deleteIds.length === 0 && (
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={classes['fab-add-row']}
            onClick={this.addFilterRow}
          >
            <Add />
          </Fab>
        )}
        {deleteIds.length === 0 && dataChanged && (
          <div className={classes['button-area']}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSave}
            >
              Speichern
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCancel}
            >
              Abbrechen
            </Button>
          </div>
        )}
        {!dataChanged && deleteIds.length > 0 && (
          <div
            className={[
              classes['button-area'],
              classes['button-area-delete'],
            ].join(' ')}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleDeleteSelected}
            >
              Löschen
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleDeleteCancel}
            >
              Abbrechen
            </Button>
          </div>
        )}
        <FilterCreateDialog
          open={this.state.createOpen}
          onClose={this.handleCreateClose}
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: selectors.getActiveUserlist(state),
  filter: selectors.getFilterList(state),
  company_id: selectors.getCompanyId(state),
});

const mapDispatchToProps = {
  saveFilter: ApiActions.saveFilter,
  loadFilter: ApiActions.loadFilter,
  updateFilter: ReduxActions.updateFilter,
  deleteFilter: ApiActions.deleteFilter,
};

type Styles = WithStyles<typeof styles>;
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(
  withStyles(styles, { withTheme: true })(FilterSettings)
);
