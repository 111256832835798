import { useState } from 'react';
import * as DateUtil from 'date-fns';
import { de } from 'date-fns/locale';

const MONTH_FORMAT = 'MMM yyyy';
export const formatMonth = (date: Date | number) => {
  return DateUtil.format(date, MONTH_FORMAT, { locale: de });
};

/**
 * Returns a stateful startDate, Monthfilter, a function to update it and prev-/next actions.
 */
const useMonthStart: (
  d: Date | number
) => [
  Date | number,
  Date | number,
  (date: string | number | Date) => void,
  () => void,
  () => void
] = (start: Date | number) => {
  const [startDate, setStartDate] = useState<Date | number>(start);
  const [endDate, setEndDate] = useState<Date | number>(
    DateUtil.endOfMonth(start)
  );

  const updateMonthFilter: (date: string | number | Date) => void = input => {
    let date: Date;
    if (typeof input === 'string') {
      date = DateUtil.parse(input, MONTH_FORMAT, startDate, { locale: de });
    } else if (typeof input === 'number') {
      date = new Date(input);
    } else {
      date = input;
    }

    setStartDate(DateUtil.startOfMonth(date));
    setEndDate(DateUtil.endOfMonth(date));
  };

  const nextMonth: () => void = () => {
    const m = DateUtil.addMonths(startDate, 1);
    updateMonthFilter(m);
  };

  const previousMonth: () => void = () => {
    const m = DateUtil.addMonths(startDate, -1);
    updateMonthFilter(m);
  };

  return [startDate, endDate, updateMonthFilter, nextMonth, previousMonth];
};

export default useMonthStart;
