import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';

const initState: UserAppState = null;
export type UserAppState = DynamoDBObjects.User | null;
export const UPDATE_APP_USER = 'UPDATE_APP_USER';
export const RESET_USER = 'RESET_USER';

export type UserActionTypes = {
  type: typeof UPDATE_APP_USER | typeof RESET_USER;
  payload: UserAppState;
};

export const updateAppUserAction = (user: UserAppState) => ({
  type: UPDATE_APP_USER,
  payload: user,
});

export const UserReducer = (
  state: UserAppState = initState,
  action: UserActionTypes
) => {
  switch (action.type) {
    case RESET_USER:
      return null;

    case UPDATE_APP_USER: {
      const u = action.payload;
      if (u) {
        return u;
      }
      return state;
    }
    default:
      return state;
  }
};
