import React, { useCallback, useMemo } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { TableHeader } from '../util/UtilComponents';
import { selectors } from '../../features/pipedrive';
import { shallowEqual, useSelector } from 'react-redux';
import { Days, DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import TimeframeTime from './TimeframeTime';

type Props = { classes?: any; frame: DynamoDBObjects.Timeframe };

const TimeframePipelineTable = ({ classes }: Props) => {
  const { setValue } = useFormContext();
  const pipelines = useSelector(selectors.getPipelineList, shallowEqual);

  const handleAllCheckboxChange = useCallback(
    (pipeline_id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      console.log(`setting all days in ${pipeline_id}: ${checked}`);

      Days.forEach((d) => {
        console.log(`\t${pipeline_id}_${d}: ${checked}`);
        setValue(`${pipeline_id}_${d}`, checked, { shouldDirty: true });
      });
    },
    [setValue]
  );

  const pipelineRows = useMemo(
    () =>
      pipelines.map((p) => {
        return (
          <TableRow key={p.pipedrive_id}>
            <TableCell>{p.name}</TableCell>
            <TableCell size="small" padding="checkbox">
              <Checkbox
                onChange={handleAllCheckboxChange(p.pipedrive_id)}
                name={`${p.pipedrive_id}_all`}
                color="primary"
              />
            </TableCell>
            {Days.map((d) => (
              <TableCell key={d}>
                <Controller
                  name={`${p.pipedrive_id}_${d}`}
                  defaultValue={false}
                  render={({ onChange, onBlur, value, name }) => (
                    <Checkbox
                      checked={value}
                      color="primary"
                      onChange={(e) => onChange(e.target.checked)}
                      name={name}
                      onBlur={onBlur}
                    />
                  )}
                />
              </TableCell>
            ))}
          </TableRow>
        );
      }),
    [handleAllCheckboxChange, pipelines]
  );

  return (
    <Table className={classes['pipeline-table']}>
      <TableHeader
        list={[
          { name: 'Pipeline' },
          { name: '', size: 'small', padding: 'checkbox' },
          { name: 'Montag' },
          { name: 'Dienstag' },
          { name: 'Mittwoch' },
          { name: 'Donnerstag' },
          { name: 'Freitag' },
          { name: 'Samstag' },
          { name: 'Sonntag' },
        ]}
      />
      <TableBody>
        {pipelineRows}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
          <TableRow className="time-row">
            <TableCell />
            <TableCell>Zeit</TableCell>
            {Days.map((d) => (
              <TableCell key={d}>
                <TimeframeTime day={d} />
              </TableCell>
            ))}
          </TableRow>
        </MuiPickersUtilsProvider>
      </TableBody>
    </Table>
  );
};

export default TimeframePipelineTable;
