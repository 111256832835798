import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbility, guestUserAbility } from './index';

class AbilityEvents {
  public static AbilityEventBase: AbilityEvents = new AbilityEvents();

  private readonly _ability: AppAbility = guestUserAbility;

  get ability(): AppAbility {
    return this._ability;
  }

  public updateAbility(newAbility: AppAbility) {
    this._ability.update(newAbility.rules);
  }
}

export default AbilityEvents.AbilityEventBase;
export const AbilityContext = createContext<AppAbility>(
  AbilityEvents.AbilityEventBase.ability
);
export const Can = createContextualCan(AbilityContext.Consumer);
