import { RootState } from '../store';

export const getActiveUserlist = (state: RootState) =>
  state.consult.users.filter(u => !('active' in u) || u.active);
export const getPipelineList = (state: RootState) => state.consult.pipelines;
export const getCurrentUser = (state: RootState) => state.user;
export const getCompanyId = (state: RootState) =>
  getCurrentUser(state)?.pipedrive_company_id || -1;
export const getFilterList = (state: RootState) => state.consult.filters;
export const getTimeframes = (state: RootState) => state.consult.timeframes;
