import {
  createStyles,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React, { Fragment, ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { selectors } from '../../features/pipedrive';
import { RootState } from '../../features/store';

interface Props extends ReduxProps, Styles {}

const styles = createStyles({
  root: {
    width: '300px',
    padding: '8px 8px 10px 10px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
    },
  },
});

function Profile({ user, classes }: Props): ReactElement {
  if (!user) return <Fragment />;
  return (
    user && (
      <Paper className={classes.root}>
        <Person fontSize="large" />
        <div>
          <Typography variant="h5">{user?.name}</Typography>
          <Typography variant="body2">{user?.email}</Typography>
        </div>
      </Paper>
    )
  );
}

const mapStateToProps = (state: RootState) => ({
  user: selectors.getCurrentUser(state),
});
const mapDispatchToProps = {};

type Styles = WithStyles<typeof styles>;
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withStyles(styles, { withTheme: true })(Profile));
