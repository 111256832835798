import { Logger } from 'aws-amplify';
import * as DateUtil from 'date-fns';
import { Days, DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';

export const SIMPLE_DATE_FORMAT = 'dd.MM.yyyy';

export const logger = new Logger(
  'sc-d',
  process.env.NODE_ENV !== 'production' ? 'INFO' : undefined
);

export function getInitials(value: string) {
  const initialsArray = value.match(/\b\w/g) || [];
  return (
    (initialsArray.shift() || '') + (initialsArray.pop() || '')
  ).toUpperCase();
}

export const compareObjects = <T extends { [key: string]: any }>(
  a: T,
  b: T
) => {
  if (a === b) return true;

  if (typeof a !== 'object' || typeof b !== 'object' || a == null || b == null)
    return false;

  let keysA = Object.keys(a),
    keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (let key of keysA) {
    if (!keysB.includes(key)) return false;

    if (typeof a[key] === 'function' || typeof b[key] === 'function') {
      if (a[key].toString() !== b[key].toString()) return false;
    } else {
      if (!compareObjects(a[key], b[key])) return false;
    }
  }

  return true;
};

export function generateTimeframeDays(
  pipelines: DynamoDBObjects.Pipeline[]
): DynamoDBObjects.TimeframeDays {
  return pipelines.reduce(
    (v, p) => ({
      ...v,
      [p.pipedrive_id]: Days.reduce((vd, d) => ({ ...vd, [d]: false }), {}),
    }),
    {}
  );
}

export function getDateFromNumber(num: number | undefined) {
  if (num === undefined) return undefined;

  return DateUtil.setHours(
    DateUtil.setMinutes(new Date(), num % 100),
    Math.trunc(num / 100)
  );
}
