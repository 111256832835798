import React from 'react';
import { Switch } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { ArrowDropDown } from '@material-ui/icons';
import { Days } from '@maom/aws-dynamodb-interfaces';
import { Controller, useFormContext } from 'react-hook-form';
import * as DateUtil from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  day: Days[number];
};

const useStyles = makeStyles({
  'time-error': {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textTransform: 'none',
  },
});

const ERROR_TIMES_INTERSECT = "Zeiten überschneiden sich";
const ERROR_TIMES_NEEDED = "Start oder Ende benötigt";

function TimeframeTime({ day }: Props) {
  const { control, watch, errors } = useFormContext();
  const isOn = watch(`useTime_${day}`);
  const timeValues = watch([`start_${day}`, `end_${day}`]);
  const styles = useStyles();

  return (
    <>
      <div className="switch-time">
        <Controller
          name={`useTime_${day}`}
          control={control}
          render={({ value, onChange, ...rest }) => (
            <Switch
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
              {...rest}
            />
          )}
        />
      </div>
      <div className="time">
        <div className="label">Von:</div>
        <Controller
          name={`start_${day}`}
          control={control}
          rules={{
            validate: (v) => {
              // wenn wir diese Funktion anmachen, brauchen wir mindestens eine Zeit
              if (isOn && !v && !timeValues[`end_${day}`]) return ERROR_TIMES_NEEDED;

              // Wenn wir nur eine Zeit haben, ist das ok, und wir können nicht berechnen
              if (!v || !timeValues[`end_${day}`]) return true;
              // Ansonsten schauen wir, dass unsere Startzeit auch vor dem Ende liegt
              return !v || DateUtil.isBefore(v, timeValues[`end_${day}`])
                ? true
                : ERROR_TIMES_INTERSECT;
            },
          }}
          render={(props) => (
            <KeyboardTimePicker
              ampm={false}
              mask="__:__"
              disabled={!isOn}
              openTo="hours"
              views={['hours', 'minutes']}
              error={!!errors[`start_${day}`] || !!errors[`end_${day}`]}
              keyboardIcon={<ArrowDropDown />}
              {...props}
            />
          )}
        />
      </div>
      <div className="time">
        <div className="label">Bis:</div>
        <Controller
          name={`end_${day}`}
          control={control}
          rules={{
            validate: (v) => {
              // wenn wir diese Funktion nutzten, brauchen wir mindestens eine Zeit
              if (isOn && !v && !timeValues[`start_${day}`]) return ERROR_TIMES_NEEDED;

              // Wenn wir nur eine Zeit haben, ist das ok, und wir können nicht berechnen
              if (!v || !timeValues[`start_${day}`]) return true;

              // Ansonsten schauen wir, dass unsere Endzeit auch nach dem Start ist.
              return !v || DateUtil.isAfter(v, timeValues[`start_${day}`])
                ? true
                : ERROR_TIMES_INTERSECT;
            },
          }}
          render={(props) => (
            <KeyboardTimePicker
              ampm={false}
              mask="__:__"
              disabled={!isOn}
              openTo="hours"
              views={['hours', 'minutes']}
              error={!!errors[`end_${day}`]}
              keyboardIcon={<ArrowDropDown />}
              {...props}
            />
          )}
        />
      </div>
      {(!!errors[`start_${day}`] || !!errors[`end_${day}`]) && (
        <div className={styles['time-error']}>
          {errors[`start_${day}`]?.message || errors[`end_${day}`]?.message}
        </div>
      )}
    </>
  );
}

export default TimeframeTime;
