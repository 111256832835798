import {
  Ability,
  AbilityBuilder,
  ConditionsMatcher,
  ForcedSubject,
  MatchConditions,
} from '@casl/ability';
import { DynamoDBObjects } from '@maom/aws-dynamodb-interfaces';

export enum Actions {
  MANAGE = 'manage', // casl keyword
  SEE = 'see',
  UPDATE = 'update',
}

export enum Subjects {
  ALL = 'all', // casl keyword
  USER_SETTINGS = 'UserSettings',
  USER = 'User',
  USER_LIST = 'user-list',
  ALL_FILTER = 'all_filter',
  ALL_TIMEFRAMES = 'all_timeframes',
  ALL_PIPELINES = 'all_pipelines',
  ALL_TIMEFRAME_TYPES = 'all_timeframe_types',
  CONSULT_PREFERENCES = 'ConsultPreferences',
  PERSONAL_DASHBOARD = 'personal_dashboard',
}

type EnumType<T> = T[keyof T];

type AppAbilities = [
  EnumType<typeof Actions>,
  (
    | EnumType<typeof Subjects>
    | ForcedSubject<Exclude<EnumType<typeof Subjects>, 'all'>>
  )
];
export type AppAbility = Ability<AppAbilities, MatchConditions>;

type DefinePermissions = (
  user: DynamoDBObjects.User,
  builder: AbilityBuilder<AppAbility>
) => void;

const Roles = ['consultant', 'admin', 'guest', 'operator'];

const rolePermissions: Record<typeof Roles[number], DefinePermissions> = {
  consultant: (u, { can }) => {
    can(Actions.SEE, Subjects.USER, ({ id }) => id === u.pipedrive_id);
    can(
      Actions.UPDATE,
      Subjects.USER,
      (obj) => obj.pipedrive_id === u.pipedrive_id
    );
    can(Actions.SEE, Subjects.ALL_TIMEFRAME_TYPES);
    can(Actions.SEE, Subjects.ALL_PIPELINES);
    can(Actions.SEE, Subjects.PERSONAL_DASHBOARD);
    can(Actions.UPDATE, Subjects.PERSONAL_DASHBOARD);
    // builder.can(Actions.UPDATE, "User", { id: u.id });
  },
  operator(u, { can }) {
    can(Actions.SEE, Subjects.USER);
    can(Actions.SEE, Subjects.ALL_TIMEFRAME_TYPES);
    can(Actions.SEE, Subjects.ALL_PIPELINES);
    can(Actions.SEE, Subjects.ALL_FILTER);
    can(Actions.SEE, Subjects.CONSULT_PREFERENCES);
  },
  guest(u, { can }) {
    can(Actions.SEE, Subjects.USER);
  },
  admin: (user, { can }) => {
    can(Actions.MANAGE, Subjects.ALL);
  },
};
const lambdaMatcher: ConditionsMatcher<MatchConditions> = (matchConditions) =>
  matchConditions;

export function defineAbilityFor(user: DynamoDBObjects.User): AppAbility {
  const builder = new AbilityBuilder<AppAbility>();

  if (
    user.role &&
    Roles.includes(user.role) &&
    typeof rolePermissions[user.role] === 'function'
  ) {
    rolePermissions[user.role](user, builder);
  } else {
    throw new Error(`Trying to use unknown role "${user.role}"`);
  }

  // return builder.build();
  return builder.build({ conditionsMatcher: lambdaMatcher });
}

const guestUser: DynamoDBObjects.User = {
  active: false,
  create_date: 0,
  email: '',
  name: 'Guest',
  pipedrive_company_id: 0,
  pipeline_to_active: {},
  pk: '',
  pipedrive_id: 1,
  role: 'guest',
};
export const guestUserAbility = defineAbilityFor(guestUser);
