import { I18n } from 'aws-amplify';
import { Translations } from '@aws-amplify/ui-components';

const dict = {
  de: {
    [Translations.BACK_TO_SIGN_IN]: 'Zurück zur Anmeldung',
    [Translations.CHANGE_PASSWORD_ACTION]: 'Veränderung',
    [Translations.CHANGE_PASSWORD]: 'Passwort ändern',
    [Translations.CODE_LABEL]: 'Verifizierungs-Code',
    [Translations.CODE_PLACEHOLDER]: 'Code eingeben',
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Bestätigungs-Code',
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Geben Sie Ihren Code ein',
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Anmeldung bestätigen',
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Haben Sie Ihren Code verloren?',
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Code erneut senden',
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Bestätigen',
    [Translations.CONFIRM_SMS_CODE]: 'SMS-Code bestätigen',
    [Translations.CONFIRM_TOTP_CODE]: 'TOTP-Code bestätigen',
    [Translations.CONFIRM]: 'Bestätigen',
    [Translations.CREATE_ACCOUNT_TEXT]: 'Account erstellen',
    [Translations.EMAIL_LABEL]: 'E-Mail-Adresse *',
    [Translations.EMAIL_PLACEHOLDER]: 'Geben Sie Ihre E-Mail-Adresse ein',
    [Translations.FORGOT_PASSWORD_TEXT]: 'Haben Sie Ihr Passwort vergessen?',
    [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:
      'Weniger als zwei mfa-Typen verfügbar',
    [Translations.NEW_PASSWORD_LABEL]: 'Neues Passwort',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Geben Sie Ihr neues Passwort ein',
    [Translations.NO_ACCOUNT_TEXT]: 'Kein Account?',
    [Translations.PASSWORD_LABEL]: 'Passwort *',
    [Translations.PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
    [Translations.PHONE_LABEL]: 'Telefonnummer *',
    [Translations.PHONE_PLACEHOLDER]: '+49 1234 567890',
    [Translations.QR_CODE_ALT]: 'qrcode',
    [Translations.RESET_PASSWORD_TEXT]: 'Password zurücksetzten',
    [Translations.RESET_YOUR_PASSWORD]: 'Password zurücksetzten',
    [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'MFA-Typ auswählen',
    [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Überprüfen',
    [Translations.SEND_CODE]: 'Code senden',
    [Translations.SUBMIT]: 'Einreichen',
    [Translations.SETUP_TOTP_REQUIRED]: 'TOTP muss konfiguriert werden',
    [Translations.SIGN_IN_ACTION]: 'Anmelden',
    [Translations.SIGN_IN_HEADER_TEXT]: 'Im Dashboard-Account einloggen',
    [Translations.SIGN_IN_TEXT]: 'Einloggen',
    [Translations.SIGN_IN_WITH_AMAZON]: 'Bei Amazon anmelden',
    [Translations.SIGN_IN_WITH_AUTH0]: 'Anmelden mit Auth0',
    [Translations.SIGN_IN_WITH_AWS]: 'Bei AWS anmelden',
    [Translations.SIGN_IN_WITH_FACEBOOK]: 'Bei Facebook anmelden',
    [Translations.SIGN_IN_WITH_GOOGLE]: 'Bei Google anmelden',
    [Translations.SIGN_OUT]: 'Abmeldung',
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'E-Mail',
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Haben Sie ein Account?',
    [Translations.SIGN_UP_HEADER_TEXT]: 'Ein neues Account erstellen',
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Kennwort',
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Account erstellen',
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Benutzername',
    [Translations.SUCCESS_MFA_TYPE]: 'Erfolg! Ihr MFA-Typ ist jetzt: ',
    [Translations.TOTP_HEADER_TEXT]:
      'Scannen und dann Verifizierungscode eingeben',
    [Translations.TOTP_LABEL]: 'Sicherheitscode eingeben: ',
    [Translations.TOTP_ISSUER]: 'AWSCognito',
    [Translations.TOTP_SETUP_FAILURE]: 'TOTP-Einrichtung ist fehlgeschlagen',
    [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Sicherheits-Token verifizieren',
    [Translations.TOTP_SUCCESS_MESSAGE]: 'TOTP erfolgreich eingerichtet!',
    [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:
      'Gescheitert! MFA kann zur Zeit nicht konfiguriert werden',
    [Translations.USERNAME_LABEL]: 'Benutzername *',
    [Translations.USERNAME_PLACEHOLDER]: 'Geben Sie Ihren Benutzernamen ein',
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'E-Mail',
    [Translations.VERIFY_CONTACT_HEADER_TEXT]:
      'Accountwiederherstellung erfordert verifizierte Kontaktinformationen',
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Telefonnummer',
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Einreichen',
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verifizieren',
    [Translations.ADDRESS_LABEL]: 'Adresse',
    [Translations.ADDRESS_PLACEHOLDER]: 'Geben Sie Ihre Adresse ein',
    [Translations.NICKNAME_LABEL]: 'Spitzname',
    [Translations.NICKNAME_PLACEHOLDER]: 'Geben Sie Ihren Spitznamen ein',
    [Translations.BIRTHDATE_LABEL]: 'Geburtstag',
    [Translations.BIRTHDATE_PLACEHOLDER]: 'Geben Sie Ihren Geburtstag ein',
    [Translations.PICTURE_LABEL]: 'Bild-URL',
    [Translations.PICTURE_PLACEHOLDER]: 'Geben Sie Ihre Bild-URL ein',
    [Translations.FAMILY_NAME_LABEL]: 'Familienname',
    [Translations.FAMILY_NAME_PLACEHOLDER]: 'Geben Sie Ihren Familiennamen ein',
    [Translations.PREFERRED_USERNAME_LABEL]: 'Bevorzugter Benutzername',
    [Translations.PREFERRED_USERNAME_PLACEHOLDER]:
      'Geben Sie Ihren bevorzugten Benutzernamen ein',
    [Translations.GENDER_LABEL]: 'Geschlecht',
    [Translations.GENDER_PLACEHOLDER]: 'Geben Sie Ihr Geschlecht ein',
    [Translations.PROFILE_LABEL]: 'Profil-URL',
    [Translations.PROFILE_PLACEHOLDER]: 'Geben Sie Ihre Profil-URL ein',
    [Translations.GIVEN_NAME_LABEL]: 'Vorname',
    [Translations.GIVEN_NAME_PLACEHOLDER]: 'Geben Sie Ihren Vornamen ein',
    [Translations.ZONEINFO_LABEL]: 'Zeitzone',
    [Translations.ZONEINFO_PLACEHOLDER]: 'Geben Sie Ihre Zeitzone ein',
    [Translations.LOCALE_LABEL]: 'Schauplatz',
    [Translations.LOCALE_PLACEHOLDER]: 'Geben Sie Ihr Gebietsschema ein',
    [Translations.UPDATED_AT_LABEL]: 'Aktualisiert am',
    [Translations.UPDATED_AT_PLACEHOLDER]:
      'Geben Sie die Zeit ein, zu der die Informationen zuletzt aktualisiert wurden',
    [Translations.MIDDLE_NAME_LABEL]: 'Mittlerer Name',
    [Translations.MIDDLE_NAME_PLACEHOLDER]:
      'Geben Sie Ihren zweiten Vornamen ein',
    [Translations.WEBSITE_LABEL]: 'Website',
    [Translations.WEBSITE_PLACEHOLDER]: 'Geben Sie Ihre Website ein',
    [Translations.NAME_LABEL]: 'Vollständiger Name',
    [Translations.NAME_PLACEHOLDER]: 'Geben Sie Ihren vollständigen Namen ein',
    [Translations.PHOTO_PICKER_TITLE]: 'Picker-Titel',
    [Translations.PHOTO_PICKER_HINT]:
      'Ergänzender Text oder Inhalt kann diesen Platz hier einnehmen',
    [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: 'Platzhalter-Tipp',
    [Translations.PHOTO_PICKER_BUTTON_TEXT]: 'Schaltfläche',
    [Translations.IMAGE_PICKER_TITLE]: 'Profil-Foto hinzufügen',
    [Translations.IMAGE_PICKER_HINT]: 'Vorschau des Bildes vor dem Hochladen',
    [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: 'Zur Bildauswahl tippen',
    [Translations.IMAGE_PICKER_BUTTON_TEXT]: 'Hochladen',
    [Translations.PICKER_TEXT]: 'Wählen Sie eine Datei',
    [Translations.TEXT_FALLBACK_CONTENT]: 'Fallback-Inhalt',
    [Translations.CONFIRM_SIGN_UP_FAILED]:
      'Anmeldung bestätigen - Fehlgeschlagen',
    [Translations.SIGN_UP_FAILED]: 'Anmeldung fehlgeschlagen',
  },
};

I18n.putVocabularies(dict);
