import { Action, AnyAction, Dispatch } from 'redux';

export enum MessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export type MessageReducerAction = {
  msg_type: MessageType;
  msg?: string;
  type2?: string;
  successInfo?: any;
} & Action<string> &
  AnyAction;

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';

export const updateSucessMessageAction = (
  successRequestType: string,
  successInfo: any
): MessageReducerAction => ({
  type: UPDATE_MESSAGE,
  msg_type: MessageType.SUCCESS,
  type2: successRequestType,
  msg: 'Erfolgreich',
  successInfo,
});

export const updateSucessMessageDispatch = (
  successRequestType: string,
  successInfo?: any
) => (dispatch: Dispatch) =>
  dispatch(updateSucessMessageAction(successRequestType, successInfo));

export const updateErrorMessageAction = (msg: string, errorType?: string) => {
  return {
    type: UPDATE_MESSAGE,
    msg_type: MessageType.ERROR,
    type2: errorType,
    msg,
  };
};

export const updateErrorMessageDispatch = (msg: string, errorType?: string) => (
  dispatch: Dispatch
) => dispatch(updateErrorMessageAction(msg, errorType));

export const RESET_MESSAGE = 'RESET_MESSAGE';

export const resetMessageAction = (type: MessageType) => {
  return {
    type: RESET_MESSAGE,
    msg_type: type,
  };
};

// Resets the currently visible error message.
export const resetMessageDispatch = (type: MessageType) => (
  dispatch: Dispatch
) => {
  return dispatch(resetMessageAction(type));
};
