import {
  Button,
  createStyles,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import {
  Add,
  Done,
  FilterList,
  NavigateBefore,
  NavigateNext,
} from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as DateUtil from 'date-fns';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
import { ApiActions, ReduxActions } from '../../features/aws/dynamodb/actions';
import { selectors } from '../../features/pipedrive';
import { RootState } from '../../features/store';
import FrameContainer from './FrameContainer';
import YearTimeline from './YearTimeline';
import useMonthStart from '../../util/hooks/useMonthStart';
import { generateTimeframeDays, getInitials } from '../../util';
import DynamoDBAPI from '../../features/aws/dynamodb/api';
import TimeframeDialogForm from './TimeframeDialogClass';

const styles = createStyles({
  active: {
    '& .MuiButton-label': {
      borderBottom: '3px solid #7CB342',
    },
  },
  hint: {
    fontSize: '0.9em',
    opacity: '0.7',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  avatarCol: {
    width: '50px',
    backgroundColor: '#F6F5FC',
    border: 'none',
    borderBottom: '1px solid #F6F5FC',
  },
  avatar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
  },
  avatarContainer: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    width: '48px',
    borderRadius: '50%',
    height: '48px',
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  namecol: {
    maxWidth: '210px',
    width: '210px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    backgroundColor: '#F6F5FC',
    border: 'none',
    borderRight: '10px solid #fff',
    borderBottom: '1px solid #F6F5FC',
  },
  dateinput: {
    margin: '4px',
  },
  'button-area': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div:first-child': {
      height: '80px',
      backgroundColor: '#F6F5FC',
      width: '340px',
    },
    '& button': {
      marginLeft: '10px',
    },
  },
  'filter-left': {
    backgroundColor: '#F6F5FC',
    width: '340px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    '& .date-picker input': {
      maxWidth: '100px',
    },
  },
  fab: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },
  'filter-button': {
    '& svg': {
      color: '#7CB342',
    },
  },
});

interface Props extends ReduxProps, Styles {}

const Timeframes = ({
  userlist,
  company_id,
  classes,
  loadTimeframes,
  timeframes,
  updateTimeframe,
  saveTimeframe,
  pipelines,
}: Props) => {
  const [dataChanged, setDataChanged] = useState(false);
  const [createFrameDayOfMonth, setCreateFrameDayOfMonth] = useState<
    { start: number; userid: number } | undefined
  >(undefined);
  const [
    startDate,
    endDate,
    updateMonthFilter,
    nextMonth,
    previousMonth,
  ] = useMonthStart(DateUtil.startOfMonth(Date.now()));
  const [view, setView] = useState<'month' | 'year'>('month');
  const [filterMenuRef, setFilterMenuRef] = useState<HTMLElement | undefined>(
    undefined
  );
  const [filterUser, setFilterUser] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!timeframes) {
      loadTimeframes(true);
    }
  }, [loadTimeframes, timeframes]);

  const userTimeFrameList: JSX.Element[] = useMemo(() => {
    const handleOpenCreateDailog = (userid: number) => (dayofmonth: number) =>
      setCreateFrameDayOfMonth({ start: dayofmonth, userid });
    return userlist
      .filter((u) => !!u)
      .filter((u) => !filterUser || filterUser === u.pipedrive_id)
      .map((u) => {
        return (
          <TableRow key={u.pk}>
            <TableCell size="small" className={classes.avatarCol}>
              <div className={classes.avatarContainer}>
                <div className={classes.avatar}>{getInitials(u.name)}</div>
              </div>
            </TableCell>
            <TableCell className={classes.namecol} size="small">
              {u.name}
              <br />
              <span className={classes.hint}>{u.email}</span>
            </TableCell>
            <TableCell size="medium" padding="none">
              <FrameContainer
                openCreateDialog={handleOpenCreateDailog(u.pipedrive_id)}
                startDate={startDate}
                endDate={endDate}
                frames={(timeframes || []).filter(
                  (frame) => frame.userid === u.pipedrive_id
                )}
                onChange={() => setDataChanged(true)}
              />
            </TableCell>
          </TableRow>
        );
      });
  }, [
    userlist,
    filterUser,
    classes.hint,
    classes.avatar,
    classes.avatarContainer,
    classes.avatarCol,
    classes.namecol,
    timeframes,
    startDate,
    endDate,
  ]);

  const baseTimeframeDays = useMemo(() => {
    return generateTimeframeDays(pipelines);
  }, [pipelines]);

  /**
   * MenuItems für den Button 'Filter'
   */
  const userFilterOptions = useMemo(() => {
    const opts = [];
    opts.push(
      <MenuItem
        key={-1}
        onClick={() => {
          setFilterUser(undefined);
          setFilterMenuRef(undefined);
        }}
      >
        Kein Filter
      </MenuItem>
    );
    opts.push(<hr key={-2} />);
    opts.push(
      ...userlist.map((u) => (
        <MenuItem
          onClick={() => {
            setFilterUser(u.pipedrive_id);
            setFilterMenuRef(undefined);
          }}
          key={u.pipedrive_id}
        >
          {filterUser === u.pipedrive_id ? (
            <Done />
          ) : (
            <div style={{ height: '24px', width: '24px' }} />
          )}
          <span>{u.name}</span>
        </MenuItem>
      ))
    );
    return opts;
  }, [userlist, filterUser]);

  const handleSave = () => {
    console.log(`saving timeframes`);

    if (timeframes) saveTimeframe(timeframes);
    setDataChanged(false);
  };

  const handleCancel = () => {
    console.log(`cancle settings, restoring old state`);
    loadTimeframes();
    setDataChanged(false);
  };

  return (
    <div style={{ paddingRight: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={classes['filter-left']}>
          <Button
            onClick={() => {
              updateMonthFilter(DateUtil.startOfMonth(Date.now()));
            }}
          >
            Heute
          </Button>
          <IconButton
            onClick={() => {
              previousMonth();
            }}
          >
            <NavigateBefore />
          </IconButton>
          <IconButton
            onClick={() => {
              nextMonth();
            }}
          >
            <NavigateNext />
          </IconButton>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <DatePicker
              variant="inline"
              label={null}
              openTo="month"
              views={['year', 'month']}
              format="MMMM yyyy"
              value={startDate}
              onChange={(date) => updateMonthFilter(date as Date)}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div style={{ flex: 1 }} />
        <Button
          onClick={() => setView('month')}
          className={view === 'month' ? classes.active : ''}
        >
          Monat
        </Button>
        <Button
          disabled
          onClick={() => setView('year')}
          className={view === 'year' ? classes.active : ''}
        >
          Jahr
        </Button>
        <div style={{ width: '2px', background: '#CBCBCB', height: '30px' }} />
        <Button
          className={classes['filter-button']}
          startIcon={<FilterList />}
          onClick={(e) => setFilterMenuRef(e.currentTarget)}
        >
          Filter
        </Button>
        <Menu
          open={Boolean(filterMenuRef)}
          anchorEl={filterMenuRef}
          onClose={() => setFilterMenuRef(undefined)}
        >
          {userFilterOptions}
        </Menu>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.avatarCol} />
              <TableCell className={classes.namecol} size="small" />
              <TableCell padding="none">
                <YearTimeline startDate={startDate} endDate={endDate} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{userTimeFrameList}</TableBody>
        </Table>
      </TableContainer>
      <div className={classes['button-area']}>
        <div />
        {dataChanged && (
          <div>
            <Button variant="contained" onClick={handleCancel}>
              Abbrechen
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Speichern
            </Button>
          </div>
        )}
      </div>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        size="small"
        onClick={() => {
          setCreateFrameDayOfMonth({
            start: new Date().getDate() - 1,
            userid: -1,
          });
        }}
      >
        <Add />
      </Fab>
      {createFrameDayOfMonth != null && (
        <TimeframeDialogForm
          onClose={() => setCreateFrameDayOfMonth(undefined)}
          onSave={(f) => {
            updateTimeframe({
              ...f,
              id: f.start_date + f.userid,
              modified: true,
            });
            setDataChanged(true);
          }}
          frame={{
            pipedrive_company_id: DynamoDBAPI.getCompanyID() || company_id,
            id: -1,
            userid: createFrameDayOfMonth.userid,
            start_date: DateUtil.startOfDay(
              DateUtil.addDays(startDate, createFrameDayOfMonth.start)
            ).getTime(),
            title: '',
            days: baseTimeframeDays,
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userlist: selectors.getActiveUserlist(state),
  pipelines: selectors.getPipelineList(state).filter((p) => p.active),
  company_id: selectors.getCompanyId(state),
  timeframes: state.consult.timeframes,
});

const mapDispatchToProps = {
  loadTimeframes: ApiActions.loadTimeframes,
  updateTimeframe: ReduxActions.updateTimeframe,
  saveTimeframe: ApiActions.saveTimeframe,
};

type Styles = WithStyles<typeof styles>;
const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withStyles(styles, { withTheme: true })(Timeframes));
